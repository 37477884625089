import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss']
})
export class UniversityComponent implements OnInit {

  universities: Array<Item>;

  loading = false;
  deleteShowModal = false;
  deleteId;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getUniversities();
  }

  getUniversities() {
    this.accountService.getUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.universities.push(new Item(element._id, element.name, element.address, element.pinCode));
          });
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickEdit(id){
    this.router.navigate([`university/add-university/${id}`]);
  }

  onClickDelete(id){
    this.deleteId = id;
    this.deleteShowModal = true;    
  }

  hide(){
    this.deleteShowModal = false;
  }

  onClickDeleteAction(){
    this.loading = true;
    this.accountService.deleteUniverSity(this.deleteId)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.getUniversities();
      }else{
        this.toastr.error('Delete Institution Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
      this.deleteShowModal = false;
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      this.deleteShowModal = false;
    });
  }
}


export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string, address:string, pincode:string) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.pincode = pincode
    }

    id:string;
    name:string;
    address:string;
    pincode:string
}