import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-admin-user',
  templateUrl: './add-admin-user.component.html',
  styleUrls: ['./add-admin-user.component.scss']
})
export class AddAdminUserComponent implements OnInit {

  addAdminForm: FormGroup;
  submitted = false;
  loading = false;

  key;
  buttonText

  isEdit = false;
  isSuspendTrue;
  isSuspendFalse;
  isActiveTrue;
  isActiveFalse;
  showPassword = false;

  universities: Array<Item>;
  universitySelected;
  university;

  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute, private router: Router) {
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
     }

  ngOnInit(): void {
    this.addAdminForm = this.formBuilder.group({
      university: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: [''],
      mobileNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email], this.isEmailUnique.bind(this)],
      password: ['', [Validators.required]],
      isSuspend: [''],
      isActive: [''],
    });

    if(this.key){
      this.buttonText = 'Update';
      this.getAdminById(this.key);
      this.isEdit = true;
    }else{
      this.buttonText = 'Save';
      this.getUniversities();
    }
  }

   // convenience getter for easy access to form fields
   get f() { return this.addAdminForm.controls; }


   getAdminById(id) {
    this.accountService.getAdminById(id)
      .subscribe((result: any) => {
        if (result.status) {
          this.university = result.data.institution; 
         this.addAdminForm.controls['firstName'].setValue(result.data.user.firstName);
         this.addAdminForm.controls['lastName'].setValue(result.data.user.lastName);
         this.addAdminForm.controls['mobileNumber'].setValue(result.data.user.mobileNumber);
         this.addAdminForm.controls['email'].setValue(result.data.user.email);
         this.addAdminForm.controls['password'].setValue(result.data.user.password);
         this.getUniversities();
         if(result.data.user.isSuspend){
            this.isSuspendTrue = true;
            this.isSuspendFalse = false;
            this.addAdminForm.controls['isSuspend'].setValue(true);
         } else if(!result.data.user.isSusbend){
            this.isSuspendTrue = false;
            this.isSuspendFalse = true;
            this.addAdminForm.controls['isSuspend'].setValue(false);
         }

         if(result.data.user.isActive){
          this.isActiveTrue = true;
          this.isActiveFalse = false;
          this.addAdminForm.controls['isActive'].setValue(true);
          } else if(!result.data.user.isActive){
              this.isActiveTrue = false;
              this.isActiveFalse = true;
              this.addAdminForm.controls['isActive'].setValue(false);
          }         
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onSuspendRadioChange(value){
    if(value == 'isSuspendTrue'){
      this.isSuspendTrue = true;
      this.isSuspendFalse = false;
      this.addAdminForm.controls['isSuspend'].setValue(true);
    }else{
      this.isSuspendTrue = false;
      this.isSuspendFalse = true;
      this.addAdminForm.controls['isSuspend'].setValue(false);
    }
  }

  onActiveRadioChange(value){
    if(value == 'isActiveTrue'){
      this.isActiveTrue = true;
      this.isActiveFalse = false;
      this.addAdminForm.controls['isActive'].setValue(true);
    }else{
      this.isActiveTrue = false;
      this.isActiveFalse = true;
      this.addAdminForm.controls['isActive'].setValue(false);
    }
  }

  isEmailUnique(control: FormControl) {
    const data = {
      "email": this.addAdminForm.controls['email'].value
    };
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        if(this.isEdit){
          resolve(null);
          return q;
        }
        
        this.accountService.emailExistWithRole(
          this.addAdminForm.controls['email'].value,
          'admin'
          ).subscribe((result) => {
          if(result.status){
            resolve({ 'isEmailUnique': result.status });
          } else {
            resolve(null);
          }       
        });
      }, 1000);
    });
    return q;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onClickAddAdmin(){
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.addAdminForm.invalid) {
      this.loading = false;
        return;
    }

    const  data = {
      "universityId": this.addAdminForm.controls['university'].value,
      "firstName": this.addAdminForm.controls['firstName'].value,
      "lastName": this.addAdminForm.controls['lastName'].value,
      "mobileNumber": this.addAdminForm.controls['mobileNumber'].value,
      "email": this.addAdminForm.controls['email'].value,
      "password": this.addAdminForm.controls['password'].value,
      "role": 'admin',
      "isSuspend": this.addAdminForm.controls['isSuspend'].value,
      "isActive": this.addAdminForm.controls['isActive'].value,
    }

    if(!this.key){
      this.accountService.createUser(data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.goToAdminPage();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      } else {
        this.accountService.editAdminUser(this.key, data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.goToAdminPage();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      }
  }

  goToAdminPage(){
    this.router.navigate(['/admin-user']);
  }

  onClickShowPassword(){
    if(this.showPassword){
      this.showPassword = false;
    }else{
      this.showPassword = true;
    }    
  }

  getUniversities() {
    this.accountService.getUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {          
          result.data.forEach((element,index) => {     
            if(this.key){
              if(this.university){
                this.universitySelected = this.university._id;
                this.addAdminForm.controls['university'].setValue(this.university._id); 
              }                                
             }else{
              if(index == 0){
                this.universitySelected = element._id;
                this.addAdminForm.controls['university'].setValue(element._id); 
              }   
             }
            this.universities.push(new Item(element._id, element.name));
          });
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onUniversitySelected(event) {
    const value = event.target.value;
    this.universitySelected = value;
    this.addAdminForm.controls['university'].setValue(this.universitySelected);
 }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}
