import {Injectable} from '@angular/core';

export interface StudentNavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends StudentNavigationItem {
  children?: StudentNavigationItem[];
}

let StudentNavigationItems = [
    {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: 'feather icon-home',
            classes: 'nav-item',
          },
          {
            id: 'subject',
            title: 'Subjects',
            type: 'item',
            url: '/subject',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-subject',
                title: 'Add Subject',
                type: 'item',
                url: '/subject/add-subject',
              }           
            ]
          },
          {
            id: 'unit',
            title: 'Modules',
            type: 'item',
            url: '/module',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-module',
                title: 'Add Module',
                type: 'item',
                url: '/module/add-module',
              },
              {
                id: 'topic',
                title: 'Topics',
                type: 'item',
                url: '/module/topic?key=5ec8a6fdbce144278c8ac445',
                key: '5ec8a6fdbce144278c8ac445',
                children: [
                  {
                    id: 'add-content',
                    title: 'Add Content',
                    type: 'item',
                    url: '/module/topic/addcontent',
                  },
                  {
                    id: 'content',
                    title: 'Content',
                    type: 'item',
                    url: '/module/topic/content?key=5ec8af7972247f2d889c4e54',
                    key: '5ec8af7972247f2d889c4e54',
                  },
                  {
                    id: 'content',
                    title: 'Content',
                    type: 'item',
                    url: '/module/topic/content?key=5ec8b01272247f2d889c4e55',
                    key: '5ec8b01272247f2d889c4e55',
                  }
                ]
              },
              {
                id: 'topic',
                title: 'Topics',
                type: 'item',
                url: '/module/topic?key=5ec8a75ada6e7d2a1c94a0cc',
              }             
            ]
          }
        ]        
    }]
  
    @Injectable()
  export class StudentNavigationItem {
    get() {
      return StudentNavigationItems;
    }
  }