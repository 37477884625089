import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddBatchRoutingModule } from './add-batch-routing.module';
import { AddBatchComponent } from './add-batch.component';
import { CardModule } from 'src/app/theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddBatchComponent],
  imports: [
    CommonModule,
    AddBatchRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddBatchComponent]
})
export class AddBatchModule { }
