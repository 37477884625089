import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { UploadAdapter } from './UploadAdapter';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-addcontent',
  templateUrl: './addcontent.component.html',
  styleUrls: ['./addcontent.component.scss']
})
export class AddcontentComponent implements OnInit {

  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  @ViewChild('apkaEditor') apkaEditor: any;
  contentTypes = [];
  public Editor = DecoupledEditor
  contentType: Number;
  editorData;

  key;
  addContentForm: FormGroup;
  submitted = false;
  loading = false;

  isFileUploaded = false;
  FileCount = 0;
  fileName = "Choose file";
  filePath = "";
  uploadLoader = false;

  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute,
    private router: Router) { 
      this.route.params.subscribe(params => {
        this.key = params['id'];
      });
    }

  ngOnInit(): void {  
    
    this.addContentForm = this.formBuilder.group({
      contentType: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: [''],
      pdfFile: [''],
      pptFile: ['']
    });

    this.contentTypes = this.getContentTypes();
    this.contentType = 1;
    this.addContentForm.controls['contentType'].setValue(1);  
  }

  // convenience getter for easy access to form fields
  get f() { return this.addContentForm.controls; }

  public onChange( { editor }: ChangeEvent ) {
    this.editorData = this.apkaEditor.editorInstance.getData();
  }

  onReady($event){
    $event.ui.getEditableElement().parentElement.insertBefore(
      $event.ui.view.toolbar.element,
      $event.ui.getEditableElement()
    );
    $event.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader);
    };
  }

  onClickSave() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.addContentForm.invalid) {
      this.loading = false;
        return;
    }

    if(this.contentType == 1 && !this.editorData){
      this.loading = false;
      this.toastr.error('Content Cannot Empty', 'Error', {
        timeOut: 3000
      });
      return;
    }
    let  data;
    if(this.contentType == 1){
      data = {
        "unitId": this.key,
        "contentType": this.getContentTypes().find(x=>x.id == this.addContentForm.controls['contentType'].value).value ,
        "title": this.addContentForm.controls['title'].value,
        "description": this.addContentForm.controls['description'].value,
        "data": this.editorData
      }
    } else if(this.contentType == 2){
      data = {
        "unitId": this.key,
        "contentType": this.getContentTypes().find(x=>x.id == this.addContentForm.controls['contentType'].value).value ,
        "title": this.addContentForm.controls['title'].value,
        "description": this.addContentForm.controls['description'].value,
        "data": this.addContentForm.controls['videoUrl'].value
      }
    } else if(this.contentType == 3){

      if(!this.isFileUploaded){
        this.toastr.error('Please Upload PDF File', 'Error', {
          timeOut: 3000
        });
        return;
      }
      data = {
        "unitId": this.key,
        "contentType": this.getContentTypes().find(x=>x.id == this.addContentForm.controls['contentType'].value).value ,
        "title": this.addContentForm.controls['title'].value,
        "description": this.addContentForm.controls['description'].value,
        "data": this.filePath
      }
    } else if(this.contentType == 4){

      if(!this.isFileUploaded){
        this.toastr.error('Please Upload PPT File', 'Error', {
          timeOut: 3000
        });
        return;
      }
      data = {
        "unitId": this.key,
        "contentType": this.getContentTypes().find(x=>x.id == this.addContentForm.controls['contentType'].value).value ,
        "title": this.addContentForm.controls['title'].value,
        "description": this.addContentForm.controls['description'].value,
        "data": this.filePath
      }
    }
    
    
    this.accountService.addContent(data)
        .subscribe((result) => {
            if(result.status){
              this.toastr.success(result.msg, 'Success', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
              this.FileCount = 0;
              this.fileName = "Choose file"; 
              this.router.navigate([`module/topic/${this.key}`]);
            }else{
              this.toastr.error('Error occured please try again', 'Error', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
            }
      }, err => { 
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
        this.loading = false;
        this.submitted = false;
     });
  }

  onClickCancel() {

  }

  getContentTypes() {
    return [
      { id: '1', name: 'Content', value: 'text' },
      { id: '2', name: 'Video', value: 'video' },
      { id: '3', name: 'PDF', value: 'pdf' },
      { id: '4', name: 'PPT', value: 'ppt' }
    ];
  }

  ddlChange(selectedValue) {
    this.contentType = selectedValue;
    this.addContentForm.controls['contentType'].setValue(selectedValue); 
    if(this.contentType == 2){
      this.addContentForm.addControl('videoUrl', new FormControl('', [Validators.required, Validators.pattern(this.urlRegex)]));
      this.addContentForm.controls['pdfFile'].clearValidators();
    } else if(this.contentType == 3){
      this.addContentForm.controls['pdfFile'].setValidators([Validators.required]);
      this.addContentForm.controls['pptFile'].clearValidators();
      this.addContentForm.removeControl('videoUrl');
    }  else if(this.contentType == 4){
      this.addContentForm.controls['pptFile'].setValidators([Validators.required]);
      this.addContentForm.controls['pdfFile'].clearValidators();
      this.addContentForm.removeControl('videoUrl');
    } else {
      this.addContentForm.controls['pdfFile'].clearValidators();
      this.addContentForm.controls['pptFile'].clearValidators();
      this.addContentForm.removeControl('videoUrl');
    }
    this.addContentForm.updateValueAndValidity();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if(this.contentType == 4){
        this.addContentForm.get('pptFile').setValue(file);
      }else{
        this.addContentForm.get('pdfFile').setValue(file);
      }      
      this.FileCount = event.target.files.length;
      this.fileName = file.name;
    }
  }

  onFileUpload() {
    if(this.FileCount > 0) {
        this.uploadLoader = true;
        const formData = new FormData();
        if(this.contentType == 4){
          formData.append('pdf', this.addContentForm.get('pptFile').value);
        }else{
          formData.append('pdf', this.addContentForm.get('pdfFile').value);
        }
        

        this.accountService.fileUpload(formData).subscribe((result: any) => {
          if(result.status){
            this.isFileUploaded = true;  
            this.filePath = result.data;
            console.log(this.filePath);
            this.uploadLoader = false;
            this.toastr.success('File Upload Successfully', 'Success', {
              timeOut: 3000
            });
          }else{
            this.isFileUploaded = false; 
            this.uploadLoader = false;
            this.toastr.error('File Upload Failed', 'Error', {
              timeOut: 3000
            });
          }
        }, err => { 
          this.isFileUploaded = false; 
          this.uploadLoader = false;
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
      });
    } else {
      this.isFileUploaded = false; 
          this.toastr.error('Please Select File', 'Error', {
          timeOut: 3000
      });
    }
  }

  goToTopic(){
    this.router.navigateByUrl(`/module/topic/${this.key}`)
  }
}
