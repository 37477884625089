import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssignProfessorRoutingModule } from './assign-professor-routing.module';
import { AssignProfessorComponent } from './assign-professor.component';
import { CardModule } from '../theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [AssignProfessorComponent],
  imports: [
    CommonModule,
    AssignProfessorRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AssignProfessorComponent]
})
export class AssignProfessorModule { }
