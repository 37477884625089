<app-card cardTitle="Add Subject" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
      <form [formGroup]="addSubjectForm">
        <div class="card-body text-center">
            <div class="form-group mb-3 w-75">
              <label for="universityName" class="float-left">Institution Name</label>
                <select class="form-control" (change)='onUniversitySelected($event)' [attr.disabled]="(isTeacher === true || isAdmin === true) ? 'disabled' : null"
                id="university"name="university" formControlName="university" 
                [ngClass]="{'is-invalid': !f.university.valid && (f.university.dirty || f.university.touched || submitted)}">
                  <option *ngFor="let item of universities" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.university.valid && (f.university.dirty || f.university.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.university.errors?.required">Institution is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Department Name</label>
                <select class="form-control" (change)='onDepartmentSelected($event)'
                id="department"name="department" formControlName="department" 
                [ngClass]="{'is-invalid': !f.department.valid && (f.department.dirty || f.department.touched || submitted)}">
                  <option *ngFor="let item of departments" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.department.valid && (f.department.dirty || f.department.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.department.errors?.required">Department is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Course Name</label>
                <select class="form-control" (change)='onCourseSelected($event)'
                id="course"name="course" formControlName="course" 
                [ngClass]="{'is-invalid': !f.course.valid && (f.course.dirty || f.course.touched || submitted)}">
                  <option *ngFor="let item of courses" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.course.valid && (f.course.dirty || f.course.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.course.errors?.required">Course is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Semester Name</label>
                <select class="form-control" (change)='onSemesterSelected($event)'
                id="semester"name="semester" formControlName="semester" 
                [ngClass]="{'is-invalid': !f.semester.valid && (f.semester.dirty || f.semester.touched || submitted)}">
                  <option *ngFor="let item of semesters" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.semester.valid && (f.semester.dirty || f.semester.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.semester.errors?.required">Semester is required</div>
                </div>
              </div>
          <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Subject Name</label>
            <input type="text" class="form-control" placeholder="Subject Name"id="subjectName" name="subjectName"
            formControlName="subjectName" [ngClass]="{'is-invalid': f.subjectName.invalid && (f.subjectName.dirty || f.subjectName.touched || submitted)}"/>
            <div *ngIf="!f.subjectName.valid && (f.subjectName.dirty || f.subjectName.touched || f.subjectName.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.subjectName.errors?.required">Subject is required</div>
            </div> 
           </div>      
           <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/subject']">Cancel</button>
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddSubject()">{{buttonText}}</button>
        </div>
        </form>
    </div>       
  </app-card>