import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {

  editShowModal: boolean;
  deleteShowModal: boolean;
  addShowModal: boolean;

  units: Array<Item>;
  loading;

  key;
  isStudent = false;
  isStudentPage = false;
  isTeacher = false;
  isAdmin = false;

  deleteID;

  p: number = 1;
  perPage: number = 2;
  totalRecordsCount: number;
  loadingPagination: boolean;

  universities: Array<Item>;
  departments: Array<Item>;
  courses: Array<Item>;
  batches: Array<Item>;
  subjects: Array<Item>;

  universitySelected;
  departmentSelected;
  courseSelected;
  subjectSelected;
  batchSelected;

  subjectName;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router,
    private route: ActivatedRoute, private authService: AuthService) { 
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
    }

  ngOnInit(): void {
    this.universitySelected = null;
    this.departmentSelected = null;
    this.courseSelected = null;
    this.subjectSelected = null;
    this.batchSelected = null;
    if(this.authService.getRole() == 'student'){
      if(!this.key){
        this.router.navigate([`subject`]);
      }else{
        this.isStudent = true;
        this.isStudentPage = true;
        this.getUnitsByStudent(this.p);
      }
    }else if(this.authService.getRole() == 'professor'){
      if(!this.key){
        this.router.navigate([`subject`]);
      }else{
        this.isTeacher = true;
        //this.getUnitsByTeacher();
        this.subjectSelected = this.key;
        this.getSubjectById(this.subjectSelected);
      }
    }else if(this.authService.getRole() == 'admin'){
      this.isAdmin = true;
      //this.getUnitsByAdmin();
      this.getAdminUniversities();           
    }else{
      this.getUnits(this.p);
      this.getUniversities();
    }
  }

  
  getUnits(page: number) {
    this.loadingPagination = true;

    const  data = {
      "pageNumber": page,
      "nPerPage": this.perPage,
      "universityId": this.universitySelected,
      "courseId": this.courseSelected,
      "subjectId": this.subjectSelected,
      "batchId": this.batchSelected
    }

    this.accountService.getUnitsWithPagination(data)
      .subscribe((result: any) => {
        this.units = Array<Item>();
        if (result.status) {    
               
          result.data.records.forEach((element, index) => {
            this.units.push(new Item(element._id, element.name, element.subject.name, element.course.name, element.university.name, element.createdBy));
          });
          this.totalRecordsCount = result.data.totalRecords;
          this.p = page;
          this.loadingPagination = false;
        } else {
          this.units = null;
        }      
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getUnitsByAdmin() {
    this.accountService.getUnitsByAdmin()
      .subscribe((result: any) => {
        this.units = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.units.push(new Item(element._id, element.name, element.subjectId.name, element.subjectId.courseId.name, element.subjectId.courseId.universityId.name, element.createdBy));
          });
        } else {
          this.units = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getUnitsByTeacher() {
    this.accountService.getUnitsByTeacher(this.key)
      .subscribe((result: any) => {
        this.units = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.units.push(new Item(element._id, element.name, element.subjectId.name, element.subjectId.courseId.name, element.subjectId.courseId.universityId.name, element.createdBy));
          });
        } else {
          this.units = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getUnitsByStudent(page){
    this.accountService.getUnitsByStudentWithPagination(this.key, page, this.perPage)
    .subscribe((result: any) => {
      this.units = Array<Item>();
      
      if (result.status) {          
        result.data.data.forEach((element, index) => {
          this.units.push(new Item(element._id, element.name, element.subjectId.name, element.subjectId.courseId.name, element.subjectId.courseId.universityId.name, element.createdBy));
        });
        this.totalRecordsCount = result.data.totalRecords;
        this.p = page;
        this.loadingPagination = false;
      } else {
        this.units = null;
        this.toastr.warning(result.msg, 'Warning', {
          timeOut: 3000
        });
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
    });
  }

  getUnitsBySubjectId() {
    this.accountService.getModulesBySubjectId(this.key)
      .subscribe((result: any) => {
        this.units = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.units.push(new Item(element._id, element.name, element.subjectId.name, element.subjectId.courseId.name, element.subjectId.courseId.universityId.name, element.createdBy));
          });
        } else {
          this.units = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickAdd(){
    this.addShowModal = true;
  }

  onClickEdit(id){
    this.router.navigate([`module/add-module/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteID = id;
  }

  hide(){
    this.editShowModal = false;
    this.deleteShowModal = false;
    this.addShowModal = false;
  }

  onClickDeleteAction(){
    this.loading = true;
    this.accountService.deleteModule(this.deleteID)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        if(this.isTeacher){
          this.getUnitsByTeacher();
        }else{
          this.getUnits(this.p);
        }        
      }else{
        this.toastr.error('Delete Module Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
      this.deleteShowModal = false;
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      this.deleteShowModal = false;
    });
  }

  goToTopic(id){
    this.router.navigate([`module/topic/${id}`]);
  }

  goToAddModule(){
    let subjectId = this.key;
    this.router.navigate([`module/add-module/${subjectId}~s`]);
  }
  
  isOwner(id){
    return this.authService.isOwner(id);
  }

  getUniversities() {
    this.accountService.getUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {       
          this.universities.push(new Item('', '---Select---','','','',''));   
          result.data.forEach((element,index) => {                    
            this.universities.push(new Item(element._id, element.name,'','','',''));
          });         
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }


  getAdminUniversities() {
    this.accountService.getAdminUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {  
          result.data.forEach((element,index) => { 
              if(index == 0){
                this.universitySelected = element._id;
              }    
            this.universities.push(new Item(element._id, element.name,'','','',''));
          });
          this.getCourses(this.universitySelected);
          this.getBatches(this.universitySelected);
          this.getUnits(this.p); 
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onUniversitySelected(event){
    const value = event.target.value;
    this.universitySelected = value;
    if(this.universitySelected != ''){
      this.getCourses(this.universitySelected);
      this.getBatches(this.universitySelected);
    }else{
      this.courses = null;
      this.batches = null;
    }    
  }

  getDepartments(id) {
    this.accountService.getDepartmentsByUniversityId(id)
      .subscribe((result: any) => {
        this.departments = Array<Item>();
        if (result.status) {    
          this.departments.push(new Item('', '---Select---','','','','')); 
          result.data.department.forEach((element, index) => {             
            this.departments.push(new Item(element._id, element.departmentName,'','','',''));
          }); 
        } else {
          this.departments = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onDepartmentSelected(event){
    const value = event.target.value;
    this.departmentSelected = value;
    if(this.departmentSelected != ''){
      this.getCourses(this.departmentSelected);
    }else{
      this.courses = null;
    }    
  }

  getCourses(id) {
    this.accountService.getCources(id)
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {    
          this.courses.push(new Item('', '---Select---','','','',''));       
          result.data.forEach((element, index) => {           
            this.courses.push(new Item(element._id, element.name,'','','',''));
          });
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onCourseSelected(event){
    const value = event.target.value;
    this.courseSelected = value;
    if(this.courseSelected != ''){
      this.getSubjects(this.courseSelected);
    }else{
      this.subjects = null;
    }   
  }

  getSubjects(id) {
    this.accountService.getSubjectsByCourseId(id)
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {  
          this.subjects.push(new Item('', '---Select---','','','',''));          
          result.data.forEach((element, index) => {     
            this.subjects.push(new Item(element._id, element.name,'','','',''));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onSubjectSelected(event){
    const value = event.target.value;
    this.subjectSelected = value;
  }

  getBatches(id) {
    this.accountService.getBatchesByUniversityId(id)
      .subscribe((result: any) => {
        this.batches = Array<Item>();
        if (result.status) {  
          this.batches.push(new Item('', '---Select---','','','','')); 
          result.data.forEach((element, index) => {
            this.batches.push(new Item(element._id,`${element.name} (${element.startYear}-${element.endYear})`,'','','',''));
          });          
        } else {
          this.batches = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getBatchesByTeacher() {
    this.accountService.getBatchesByTeacherId()
      .subscribe((result: any) => {
        this.batches = Array<Item>();
        if (result.status) {  
          this.batches.push(new Item('', '---Select---','','','','')); 
          result.data.forEach((element, index) => {                    
            this.batches.push(new Item(element._id,`${element.name} (${element.startYear}-${element.endYear})`,'','','',''));
          });          
        } else {
          this.batches = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onBatchSelected(event){
    const value = event.target.value;
    this.batchSelected = value; 
  }

  getSubjectById(id) {
    this.accountService.getSubjectById(id)
      .subscribe((result: any) => {
        if (result.status) {
          this.subjectName = result.data.name;
          this.getUnits(this.p); 
          this.getBatchesByTeacher();
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  search(){
    this.getUnits(this.p);
  }

  clearSearch(){
    if(this.isAdmin){
      this.departments = null;    
    this.subjects = null;  
    this.courseSelected = null;
    this.departmentSelected = null;    
    this.subjectSelected = null;
    this.batchSelected = null;
      this.getAdminUniversities();
    }  if(this.isTeacher){ 
      this.batchSelected = null;
      this.getSubjectById(this.subjectSelected);
    }else{
      
      this.universities = null;
      this.universitySelected = null;
      this.courses = null;
      this.departments = null;    
    this.subjects = null;  
    this.courseSelected = null;
    this.departmentSelected = null;    
    this.subjectSelected = null;
    this.batchSelected = null;
      this.getUniversities();
    }
    this.getUnits(this.p);
  }

  getPage(page){
    if(this.isStudent){
      this.getUnitsByStudent(page);
    }else{
      this.getUnits(page);
    }    
  }
}


export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string, subjectName:string, courseName:string, universityName:string, createdBy: string) {
        this.id = id;
        this.name = name;
        this.subjectName = subjectName
        this.courseName = courseName;
        this.universityName = universityName;
        this.createdBy = createdBy;
    }

    id:string;
    name:string;
    subjectName:string;
    courseName:string;
    universityName:string;
    createdBy: string;
}


