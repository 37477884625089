import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLogoComponent } from './theme/layout/admin/navigation/nav-logo/nav-logo.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavigationItem, TeacherNavigationItem, AdminNavigationItem} from './theme/layout/admin/navigation/navigation';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';
import {NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { ContentModule } from './module/topic/content/content.module';
import { ModuleModule } from './module/module.module';
import { TopicModule } from './module/topic/topic.module';
import { AddcontentModule } from './module/topic/addcontent/addcontent.module';
import { ResetPasswordModule } from './demo/pages/authentication/reset-password/reset-password.module';
import { ToastrModule } from 'ngx-toastr';
import { httpInterceptorProviders } from 'src/http-interceptors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UniversityModule } from './university/university.module';
import { AddUniversityModule } from './university/add-university/add-university.module';
import { CourseModule } from './course/course.module';
import { AddCourseModule } from './course/add-course/add-course.module';
import { SubjectModule } from './subject/subject.module';
import { AddSubjectModule } from './subject/add-subject/add-subject.module';
import { AddModuleModule } from './module/add-module/add-module.module';
import { EditcontentModule } from './module/topic/editcontent/editcontent.module';
import { StudentNavigationItem } from './theme/layout/admin/navigation/navigation-student';
import { AdminUserModule } from './admin-user/admin-user.module';
import { AddAdminUserModule } from './admin-user/add-admin-user/add-admin-user.module';
import { ProfessorModule } from './professor/professor.module';
import { AddProfessorModule } from './professor/add-professor/add-professor.module';
import { AssignProfessorModule } from './assign-professor/assign-professor.module';
import { BatchModule } from './batch/batch.module';
import { AddBatchModule } from './batch/add-batch/add-batch.module';
import { PrivacyPolicyModule } from './demo/extra/privacy-policy/privacy-policy.module';
import { StudentModule } from './student/student.module';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavLogoComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    ToggleFullScreenDirective,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    ContentModule,
    ModuleModule,
    TopicModule,
    AddcontentModule,
    ResetPasswordModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    UniversityModule,
    AddUniversityModule,
    CourseModule,
    AddCourseModule,
    SubjectModule,
    AddSubjectModule,
    AddModuleModule,
    EditcontentModule,
    AdminUserModule,
    AddAdminUserModule,
    BrowserAnimationsModule,
    ProfessorModule,
    AddProfessorModule,
    AssignProfessorModule,
    BatchModule,
    AddBatchModule,
    PrivacyPolicyModule,
    StudentModule
  ],
  providers: [
    NavigationItem,
    StudentNavigationItem,
    TeacherNavigationItem,
    AdminNavigationItem,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
