import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddCourseRoutingModule } from './add-course-routing.module';
import { AddCourseComponent } from './add-course.component';
import { CardModule } from 'src/app/theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddCourseComponent],
  imports: [
    CommonModule,
    AddCourseRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddCourseComponent]
})
export class AddCourseModule { }
