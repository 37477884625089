import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddUniversityRoutingModule } from './add-university-routing.module';
import { AddUniversityComponent } from './add-university.component';
import { CardModule } from 'src/app/theme/shared/components';


@NgModule({
  declarations: [AddUniversityComponent],
  imports: [
    CommonModule,
    AddUniversityRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddUniversityComponent]
})
export class AddUniversityModule { }
