<div class="row">
    <div class="col-xl-12">
      <app-card cardTitle="Students" [options]="false" blockClass="table-border-style">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Mobile No</th>
              <th>Course</th>
              <th>Verified</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of students;let i = index;" >
              <th scope="row">{{i+1}}</th>
              <td>{{item.email}}</td>
              <td>{{item.phoneNumer}}</td>
              <td>{{item.courseName}}</td>
              <td>{{item.isVerify}}</td>
              <td><button class="btn btn-outline-primary shadow-2" (click)="onClickVerify(item)">{{item.isApprove == true ? 'Un Approve' : 'Approve'}}</button></td>
            </tr>
            </tbody>
          </table>
        </div>
      </app-card>      
    </div>
</div>