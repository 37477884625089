import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  register(data: any) {
    const url = `/user/register`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  createUser(data: any) {
    const url = `/user`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  login(data: any) {
    const url = `/user/login`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editAdminUser(id, data: any) { 
    const url = `/user/admin/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editProfessorUser(id, data: any) { 
    const url = `/user/professor/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editAssignProfessor(id, data: any) { 
    const url = `/user/assignprofessor/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  deleteAdminUser(id){
    return this.http.delete(`/user/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  deleteProfessorUser(id){
    return this.http.delete(`/user/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  getAdminUsers(){
    return this.http.get<any>(`/user/admins`);
  }

  getProfessorUsers(){
    return this.http.get<any>(`/user/professors`);
  }

  getStudents(){
    return this.http.get<any>(`/user/students`);
  }

  studentApprove(id){
    return this.http.get<any>(`/user/${id}/approve`);
  }

  studentUnApprove(id){
    return this.http.get<any>(`/user/${id}/unapprove`);
  }

  getDepartments(){
    return this.http.get<any>(`/university/departments`);
  }

  getCourcesByDepartments(data){
    const url = `/course/departments`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  getSubjectsByCourses(data){
    const url = `/subject/courses`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  getDepartmentsByUniversityId(id){
    return this.http.get<any>(`/university/departments/${id}`);
  }

  getBatchesByUniversityId(id){
    return this.http.get<any>(`/university/${id}/batches`);
  }

  getBatchesByTeacherId(){
    return this.http.get<any>(`/batch/teacher`);
  }
  
  getAdminById(id){
    return this.http.get<any>(`/user/admin/${id}`);
  }

  getUniversities(){
    return this.http.get<any>(`/university`);
  }

  getAdminUniversities(){
    return this.http.get<any>(`/university/admin`);
  }

  getUniversitiesByUserId(){
    return this.http.get<any>(`/user/universities`);
  }

  getBatches(){
    return this.http.get<any>(`/batch`);
  }

  getCoursesByUserId(){
    return this.http.get<any>(`/user/courses`);
  }

  getCourses(){
    return this.http.get<any>(`/course`);
  }

  getCoursesByAdmin(){
    return this.http.get<any>(`/course/admin`);
  }

  getCources(universityId){
    return this.http.get<any>(`/university/${universityId}/courses`);
  }

  getCoursesByDepartmentId(departmentId){
    return this.http.get<any>(`/course/${departmentId}/courses`);
  }

  getCourcesByTeacher(universityId){
    return this.http.get<any>(`/university/${universityId}/teachercourses`);
  }

  getSubjects(){
    return this.http.get<any>(`/subject`);
  }

  getSubjectsByAdmin(){
    return this.http.get<any>(`/subject/admin`);
  }

  getSubjectsByTeacherId(){
    return this.http.get<any>(`/subject/teacher`);
  }

  getSubjectByTeacherId(id){
    return this.http.get<any>(`/subject/${id}/teacher`);
  }

  getSubjectsByCourseId(courseId){
    return this.http.get<any>(`/course/${courseId}/subjects`);
  }

  getSubjectsByTeacher(courseId){
    return this.http.get<any>(`/course/${courseId}/teachersubjects`);
  }

  getUniversityById(id){
    return this.http.get<any>(`/university/${id}`);
  }

  getSubjectById(id){
    return this.http.get<any>(`/subject/${id}`);
  }

  getCourseById(id){
    return this.http.get<any>(`/course/${id}`);
  }

  getBatchById(id){
    return this.http.get<any>(`/batch/${id}`);
  }

  getModuleById(id){
    return this.http.get<any>(`/unit/${id}`);
  }

  getModulesBySubjectId(subjectId){
    return this.http.get<any>(`/subject/${subjectId}/units`);
  }

  getUnits(){
    return this.http.get<any>(`/unit`);
  }

  getUnitsWithPagination(data){
    const url = `/unit/list`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  getUnitsByAdmin(){
    return this.http.get<any>(`/unit/admin`);
  }

  getUnitsByTeacher(subjectId){
    return this.http.get<any>(`/unit/${subjectId}/teacher`);
  }

  
  getUnitsByStudent(subjectId){
    return this.http.get<any>(`/unit/${subjectId}/student`);
  }

  getUnitsByStudentWithPagination(subjectId, pageNumber, nPerPage){
    return this.http.get<any>(`/unit/${subjectId}/${pageNumber}/${nPerPage}/student`);
  }

  getContentsByUnitId(unitId){
    return this.http.get<any>(`/unit/${unitId}/contents`);
  }

  getContentById(contentId){
    return this.http.get<any>(`/content/${contentId}`);
  }

  getAssignProfessors(){
    return this.http.get<any>(`/user/assign`);
  }

  getAssignProfessorDetails(id){
    return this.http.get<any>(`/user/assign/${id}`);
  }

  getProfessorsBySubject(id){
    return this.http.get<any>(`/user/subject/${id}/professor`);
  }

  emailExist(email){
    return this.http.get<any>(`/user/exist?email=${email}`);
  }

  emailExistWithRole(email, role){
    return this.http.get<any>(`/user/existwithrole?email=${email}&&role=${role}`);
  }

  deleteContent(id){
    return this.http.delete(`/content/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  logout(){
    return this.http.get<any>(`/user/logout`);
  }

  addUniverSity(data: any) {
    const url = `/university`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editUniverSity(id, data: any) { 
    const url = `/university/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  deleteUniverSity(id){
    return this.http.delete(`/university/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  addBatch(data: any) {
    const url = `/batch`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }
  
  editBatch(id, data: any) { 
    const url = `/batch/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  addCourse(data: any) {
    const url = `/course`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editCourse(id, data: any) { 
    const url = `/course/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  deleteBatch(id){
    return this.http.delete(`/batch/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  deleteCourse(id){
    return this.http.delete(`/course/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  addSubject(data: any) {
    const url = `/subject`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editSubject(id, data: any) { 
    const url = `/subject/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  deleteSubject(id){
    return this.http.delete(`/subject/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

  // Unit Or Module
  addModule(data: any) { 
    const url = `/unit`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editModule(id, data: any) { 
    const url = `/unit/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  deleteModule(id){
    return this.http.delete(`/unit/${id}`, {}).pipe(
      catchError((res: HttpErrorResponse) => {
      return throwError(res);
    }));
  }

   addContent(data: any) { 
    const url = `/content`;
    return this.http.post<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  editContent(id, data: any) { 
    const url = `/content/${id}`;
    return this.http.put<any>(url, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }

  // fileUpload(data) {
  //   const url = `/content/upload`;
  //   let input = new FormData();
  //   input.append('pdf', data);   // "url" as the key and "data" as value
  //   return this.http.post(url, input).pipe(map((resp: any) => resp));
  // }

  public fileUpload(data) {
    let uploadURL = `/content/upload`;
    
    return this.http.post<any>(uploadURL, data).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(res);
      }));
  }
}
