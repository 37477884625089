import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleComponent } from './module.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: ModuleComponent
  },
  {
    path: 'topic/:id',
    loadChildren: './topic/topic.module#TopicModule',
    data: { breadcrumb: 'Topics' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-module',
    loadChildren: './add-module/add-module.module#AddModuleModule',
    data: { breadcrumb: 'Add Module' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-module/:id',
    loadChildren: './add-module/add-module.module#AddModuleModule',
    data: { breadcrumb: 'Edit Module' },
    canActivate: [AuthGuard]
  },
  {
    path: ':id',
    component: ModuleComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuleRoutingModule { }
