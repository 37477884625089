import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BatchRoutingModule } from './batch-routing.module';
import { BatchComponent } from './batch.component';
import { CardModule } from '../theme/shared/components';

@NgModule({
  declarations: [BatchComponent],
  imports: [
    CommonModule,
    BatchRoutingModule,
    CardModule
  ],
  exports: [BatchComponent]
})
export class BatchModule { }
