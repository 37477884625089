import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfessorComponent } from './professor.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: ProfessorComponent
  },
  {
    path: 'add-professor',
    loadChildren: './add-professor/add-professor.module#AddProfessorModule',
    data: { breadcrumb: 'Add-Faculty' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-professor/:id',
    loadChildren: './add-professor/add-professor.module#AddProfessorModule',
    data: { breadcrumb: 'Edit-Faculty' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfessorRoutingModule { }
