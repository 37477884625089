

<app-card cardTitle="Add Institution" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
      <form [formGroup]="addUniversityForm">
        <div class="card-body text-center">
          <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Institution Name</label>
            <input type="text" class="form-control" placeholder="Institution Name"id="universityName" name="universityName"
            formControlName="universityName" [ngClass]="{'is-invalid': f.universityName.invalid && (f.universityName.dirty || f.universityName.touched || submitted)}"/>
            <div *ngIf="!f.universityName.valid && (f.universityName.dirty || f.universityName.touched || f.universityName.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.universityName.errors?.required">Institution is required</div>
            </div> 
          </div>
          <div class="form-group mb-4 w-75">
            <label for="universityName" class="float-left">Address</label>
            <input type="text" class="form-control" placeholder="Address"id="address" name="address"
            formControlName="address" [ngClass]="{'is-invalid': f.address.invalid && (f.address.dirty || f.address.touched || submitted)}"/>
            <div *ngIf="!f.address.valid && (f.address.dirty || f.address.touched || f.address.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.address.errors?.required">Address is required</div>
            </div>
          </div>
          <div class="form-group mb-4 w-75">
            <label for="universityName" class="float-left">Pincode</label>
            <input type="text" class="form-control" placeholder="Pincode"id="pinCode" name="pinCode" (keypress)="numberOnly($event)"
            formControlName="pinCode" [ngClass]="{'is-invalid': f.pinCode.invalid && (f.pinCode.dirty || f.pinCode.touched || submitted)}"/>
            <div *ngIf="!f.pinCode.valid && (f.pinCode.dirty || f.pinCode.touched || f.pinCode.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.pinCode.errors && !f.pinCode.errors?.required">PinCode minimum and maximum 6 number</div>
              <div *ngIf="f.pinCode.errors?.required">PinCode is required</div>
            </div>
          </div>
          <div>
            <table *ngIf="jsonData.length > 0" class="table w-75" style="border: 1px solid #ccc;">
              <thead class="thead-dark">
                <tr>
                  <th *ngFor="let header of _object.keys(jsonData[0]); let i = index" >{{header}}</th>
                  <th>action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of jsonData; let i = index">
                  <th class="text-left" *ngFor="let objKey of _object.keys(row); let j = index">{{ row[objKey] }} </th>
                  <th>
                    <button class="btn btn-outline-primary float-center" (click)="removeDepartment(row)">x</button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <button class="btn btn-outline-primary shadow-2 mb-4 float-left" (click)="showDepartmentModel()">Add Department</button>
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/university']">Cancel</button>
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddUniverSity()">{{buttonText}}</button>
        </div>
        </form>
    </div>       
  </app-card>

  <!-- Add Modal -->
<div class="modal" id="myModal" [style.display]="addShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header" style="height: 50px;">
        <h4 class="modal-title">Add Department</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addDepartmentForm">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Department Name"id="departmentName" name="departmentName"
            formControlName="departmentName" [ngClass]="{'is-invalid': df.departmentName.invalid && (df.departmentName.dirty || df.departmentName.touched || dSubmitted)}"/>
            <div *ngIf="!df.departmentName.valid && (df.departmentName.dirty || df.departmentName.touched || df.departmentName.errors || dSubmitted)" class="invalid-feedback">
              <div *ngIf="df.departmentName.errors?.required">Department Name is required</div>
            </div> 
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="height: 65px;">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Cancel</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addDepartment()">Add</button>
      </div>
    </div>
  </div>
</div>