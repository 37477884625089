import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-university',
  templateUrl: './add-university.component.html',
  styleUrls: ['./add-university.component.scss']
})
export class AddUniversityComponent implements OnInit {

  addDepartmentForm: FormGroup;
  dSubmitted = false;

  addUniversityForm: FormGroup;
  submitted = false;
  loading = false;

  key;
  buttonText;

  addShowModal= false;

  jsonData = [];
  _object = Object;

  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute) { 
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
    }

  ngOnInit(): void {

    this.addUniversityForm = this.formBuilder.group({
      universityName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pinCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    if(this.key){
      this.buttonText = 'Update';
      this.getUniversityById(this.key);
    }else{
      this.buttonText = 'Save';
    }
    this.addDepartmentForm = this.formBuilder.group({
      departmentName: ['', [Validators.required]]
    });
  }

   // convenience getter for easy access to form fields
   get f() { return this.addUniversityForm.controls; }

   // convenience getter for easy access to form fields
   get df() { return this.addDepartmentForm.controls; }

   getUniversityById(id) {
    this.accountService.getUniversityById(id)
      .subscribe((result: any) => {
        if (result.status) {
         this.addUniversityForm.controls['universityName'].setValue(result.data.name);
         this.addUniversityForm.controls['address'].setValue(result.data.address);
         this.addUniversityForm.controls['pinCode'].setValue(result.data.pinCode);
         this.jsonData = result.data.department;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

   onClickAddUniverSity(){

    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.addUniversityForm.invalid) {
      this.loading = false;
        return;
    }

    const  data = {
      "name": this.addUniversityForm.controls['universityName'].value,
      "address": this.addUniversityForm.controls['address'].value,
      "pinCode": this.addUniversityForm.controls['pinCode'].value,
      "department": this.jsonData
    }

    if(!this.key){
    this.accountService.addUniverSity(data)
        .subscribe((result) => {
            if(result.status){
              this.toastr.success(result.msg, 'Success', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
              this.navigateService.goToUniversity();
            }else{
              this.toastr.error('Error occured please try again', 'Error', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
            }
      }, err => { 
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
        this.loading = false;
        this.submitted = false;
     }); 
    }else{
      this.accountService.editUniverSity(this.key, data)
        .subscribe((result) => {
            if(result.status){
              this.toastr.success(result.msg, 'Success', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
              this.navigateService.goToUniversity();
            }else{
              this.toastr.error('Error occured please try again', 'Error', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
            }
      }, err => { 
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
        this.loading = false;
        this.submitted = false;
     });
    }
   }

   numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeDepartment(data){
    this.jsonData = this.jsonData.filter(item => item != data);
  }

  showDepartmentModel(){
    this.addShowModal = true;
  }

  addDepartment(){
    this.dSubmitted = true;

    // stop here if form is invalid
    if (this.addDepartmentForm.invalid) {
        return;
    }
    let uid = this.uuid();
    this.jsonData.push({ id: uid, departmentName: this.addDepartmentForm.controls['departmentName'].value})
    this.addShowModal = false;
    this.addDepartmentForm.controls['departmentName'].setValue('');
  }

  hide(){
    this.addShowModal = false;
  }

  uuid(){
   var chars = '0123456789abcdef'.split('');

   var uuid = [], rnd = Math.random, r;
   uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
   uuid[14] = '4'; // version 4

   for (var i = 0; i < 36; i++)
   {
      if (!uuid[i])
      {
         r = 0 | rnd()*16;

         uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
      }
   }

   return uuid.join('');
  }
}
