import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss']
})
export class SubjectComponent implements OnInit {

  subjects: Array<Item>;
  loading = false;

  isStudent = false;
  isTeacher = false;
  isAdmin = false;

  deleteShowModal = false;
  deleteID;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if(this.authService.getRole() == 'student'){
      this.isStudent = true;
      this.getSubjectsByCourseId(this.authService.getCourse());
    } else  if(this.authService.getRole() == 'professor'){ 
      this.isTeacher = true;
      this.getSubjectsByTeacherId();
    } else  if(this.authService.getRole() == 'admin'){ 
      this.isAdmin = true;
      this.getSubjectsByAdmin();
    } else {
      this.getSubjects();
    }
  }

  getSubjects() {
    this.accountService.getSubjects()
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.subjects.push(new Item(element._id, element.name, element.courseId.name, element.courseId.universityId.name, element.semesterId));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getSubjectsByAdmin() {
    this.accountService.getSubjectsByAdmin()
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.subjects.push(new Item(element._id, element.name, element.courseId.name, element.courseId.universityId.name, element.semesterId));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getSubjectsByTeacherId() {
    this.accountService.getSubjectsByTeacherId()
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.subjects.push(new Item(element._id, element.name, element.courseId.name, element.courseId.universityId.name, element.semesterId));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getSubjectsByCourseId(id) {
    this.accountService.getSubjectsByCourseId(id)
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.subjects.push(new Item(element._id, element.name, element.courseId.name, element.courseId.universityId.name, element.semesterId));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickView(id){
    this.router.navigate([`module/${id}`]);
  }

  onClickEdit(id){
    this.router.navigate([`subject/add-subject/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteID = id;
  }

  hide(){
    this.deleteShowModal = false;
  }

  onClickDeleteAction(){
    this.loading = true;
    this.accountService.deleteSubject(this.deleteID)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.getSubjects();
      }else{
        this.toastr.error('Delete Subject Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
      this.deleteShowModal = false;
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      this.deleteShowModal = false;
    });
  }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string, courseName:string, universityName:string, semesterName: string) {
        this.id = id;
        this.name = name;
        this.semesterName = semesterName;
        this.courseName = courseName;
        this.universityName = universityName;
    }

    id:string;
    name:string;
    semesterName:string;
    courseName:string;
    universityName:string;
}
