import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchComponent } from './batch.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: BatchComponent
  },
  {
    path: 'add-batch',
    loadChildren: './add-batch/add-batch.module#AddBatchModule',
    data: { breadcrumb: 'Add-Batch' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-batch/:id',
    loadChildren: './add-batch/add-batch.module#AddBatchModule',
    data: { breadcrumb: 'Edit-Batch' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchRoutingModule { }
