<app-card cardTitle="Units Or Modules" [options]="false" blockClass="table-border-style">
    <div class="row p-10">
      <div class="col-md-12 col-xl-12">
        <div *ngIf="!isStudent" class="float-right mb-3 bg-secondary">
          <button *ngIf="!isTeacher" class="btn btn-outline-primary" [routerLink]="['/module/add-module']">Add Module</button>
          <button *ngIf="isTeacher" class="btn btn-outline-primary" (click)="goToAddModule()">Add Module</button>
        </div>
      </div>
      <div class="col-md-12 col-xl-12">
        <!-- <app-card [hidHeader]="true" *ngFor="let item of units; let i = index;" >
          <div class="row"><i class="feather icon-book" style="color:#23b7e5"></i>&nbsp;<h6 class="mb-4">{{item.universityName}} - {{item.courseName}} - {{item.subjectName}}</h6></div>
          <div class="row d-flex align-items-center">
            <div class="col-9">
              <h3 class="f-w-300 d-flex align-items-center m-b-10">{{item.name}}</h3>
            </div>
          </div>
          <div class="float-right mb-3">
            <button class="btn btn-outline-primary" (click)="goToTopic(item.id)">View</button>
            <button *ngIf="(!isStudent && !isTeacher) || (!isStudent && (isTeacher && isOwner(item.createdBy)))" class="btn btn-outline-primary" (click)="onClickEdit(item.id)">Edit</button>
            <button *ngIf="(!isStudent && !isTeacher) || (!isStudent && (isTeacher && isOwner(item.createdBy)))" class="btn btn-outline-primary" [disabled]="loading" (click)="onClickDelete(item.id)">Delete</button>
          </div>
        </app-card> -->
        <app-card *ngIf="!isStudent" [hidHeader]="true">
          <div *ngIf="!isTeacher" class="row">
            <div class="col-6">
              <div class="form-group mb-3">
                <label for="universityName" class="float-left">Institution Name</label>
                <select class="form-control" (change)='onUniversitySelected($event)' [attr.disabled]="(isTeacher === true || isAdmin === true) ? 'disabled' : null"
                id="university"name="university" formControlName="university">
                  <option *ngFor="let item of universities" [value]="item.id">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <!-- <div class="form-group mb-3">
                <label for="departmentName" class="float-left">Department Name</label>
                <select class="form-control" (change)='onDepartmentSelected($event)'
                id="department"name="department" formControlName="department">
                  <option *ngFor="let item of departments" [value]="item.id">
                    {{item.name}}
                  </option>
                </select>
              </div> -->
              <div class="form-group mb-3">
                <label for="courseName" class="float-left">Course Name</label>
                <select class="form-control" (change)='onCourseSelected($event)' [attr.disabled]="(isTeacher === true) ? 'disabled' : null"
                id="course"name="course" formControlName="course">
                  <option *ngFor="let item of courses" [value]="item.id">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group mb-3">
                <label for="subjectName" class="float-left">Subject Name</label>
                <select *ngIf="!isTeacher" class="form-control" (change)='onSubjectSelected($event)'
                id="subject"name="subject" formControlName="subject">
                  <option *ngFor="let item of subjects" [value]="item.id">
                    {{item.name}}
                  </option>
                </select>
                <input *ngIf="isTeacher" type="text" class="form-control" placeholder="Subject Name" [attr.disabled]="'disabled'"
                value="{{subjectName}}" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mb-3">
                <label for="batchName" class="float-left">Batch</label>
                <select class="form-control" (change)='onBatchSelected($event)'
                id="batch"name="batch" formControlName="batch">
                  <option *ngFor="let item of batches" [value]="item.id">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
            
            </div>
            <div class="col-3"></div>
            <div class="col-3">
              <button class="btn btn-outline-primary" (click)="search()">Search</button>
              <button class="btn btn-outline-primary" (click)="clearSearch()">Clear</button>
            </div>
          </div>
        </app-card>
        <app-card [hidHeader]="true" *ngFor="let item of units | paginate: { id: 'server', itemsPerPage: perPage, currentPage: p, totalItems: totalRecordsCount }" >
          <div class="row"><i class="feather icon-book" style="color:#23b7e5"></i>&nbsp;<h6 class="mb-4">{{item.universityName}} - {{item.courseName}} - {{item.subjectName}}</h6></div>
          <div class="row d-flex align-items-center">
            <div class="col-9">
              <h3 class="f-w-300 d-flex align-items-center m-b-10">{{item.name}}</h3>
            </div>
          </div>
          <div class="float-right mb-3">
            <button class="btn btn-outline-primary" (click)="goToTopic(item.id)">View</button>
            <button *ngIf="(!isStudent && !isTeacher) || (!isStudent && (isTeacher && isOwner(item.createdBy)))" class="btn btn-outline-primary" (click)="onClickEdit(item.id)">Edit</button>
            <button *ngIf="(!isStudent && !isTeacher) || (!isStudent && (isTeacher && isOwner(item.createdBy)))" class="btn btn-outline-primary" [disabled]="loading" (click)="onClickDelete(item.id)">Delete</button>
          </div>
        </app-card>

        <div *ngIf="units != null && units.length == 0">
          <div class="text-center">No Records</div>
      </div>
        <div *ngIf="units != null && units.length > 0" class="has-text-centered">
          <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
          <pagination-controls class="float-right" (pageChange)="getPage($event)" id="server"></pagination-controls>
      </div>
      </div>
    </div>
</app-card>

<!-- Add Modal -->
<div class="modal" id="myModal" [style.display]="addShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Add Module</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="form-group">
          <label for="title">Title</label>
          <input type="text" class="form-control" id="title" placeholder="Title">
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input type="text" class="form-control" id="description" placeholder="Description">
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div class="modal" id="myModal" [style.display]="editShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Edit Module</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="form-group">
          <label for="title">Title</label>
          <input type="text" class="form-control" id="title" placeholder="Title">
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input type="text" class="form-control" id="description" placeholder="Description">
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div class="modal" id="myModal" [style.display]="deleteShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header" style="height: 50px;">
        <h4 class="modal-title">Delete Module</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <label>Are you sure you want to delete this module ?</label>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="height: 65px;">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Cancel</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onClickDeleteAction()">Delete</button>
      </div>
    </div>
  </div>
</div>