import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddModuleRoutingModule } from './add-module-routing.module';
import { AddModuleComponent } from './add-module.component';
import { CardModule } from 'src/app/theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AddModuleComponent],
  imports: [
    CommonModule,
    AddModuleRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddModuleComponent]
})
export class AddModuleModule { }
