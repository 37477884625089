import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-professor',
  templateUrl: './add-professor.component.html',
  styleUrls: ['./add-professor.component.scss']
})
export class AddProfessorComponent implements OnInit {

  addProfessorForm: FormGroup;
  submitted = false;
  loading = false;

  key;
  buttonText

  isEdit = false;
  isSuspendTrue;
  isSuspendFalse;
  isActiveTrue;
  isActiveFalse;

  departments: Array<Item>;
  departmentSelected;

  semesters: Array<Item>;
  semesterSelected

  educations: Array<Item>;
  educationSelected

  courses: Array<Item>;
  courseSelected

  subjects: Array<Item>;
  subjectSelected

  showPassword = false;

  batches: Array<Item>;
  batchSelected

  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute, private router: Router) {
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
    }

  ngOnInit(): void {
    this.addProfessorForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      mobileNumber: ['', [Validators.required]],
      alternateMobileNumber: [''],
      email: ['', [Validators.required, Validators.email], this.isEmailUnique.bind(this)],
      password: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      department: ['', [Validators.required]],
      education: ['', [Validators.required]],
      semester: [''],
      course: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      batch: ['', [Validators.required]],
      isSuspend: [''],
      isActive: [''],
    });

    this.getSemesters();
    this.getDepartments();
    this.getEducations();
    this.getBatches();

    if(this.key){
      this.buttonText = 'Update';
      this.getProfessorById(this.key);
      this.isEdit = true;
    }else{
      this.buttonText = 'Save';
    }   
  }

  // convenience getter for easy access to form fields
  get f() { return this.addProfessorForm.controls; }

  getProfessorById(id) {
    this.accountService.getAdminById(id)
      .subscribe((result: any) => {
        if (result.status) {
         this.addProfessorForm.controls['firstName'].setValue(result.data.user.firstName);
         this.addProfessorForm.controls['lastName'].setValue(result.data.user.lastName);
         this.addProfessorForm.controls['mobileNumber'].setValue(result.data.user.mobileNumber);
         this.addProfessorForm.controls['alternateMobileNumber'].setValue(result.data.user.alternateMobileNumber);
         this.addProfessorForm.controls['email'].setValue(result.data.user.email);
         this.addProfessorForm.controls['password'].setValue(result.data.user.password);
         this.addProfessorForm.controls['designation'].setValue(result.data.user.designation);
         this.addProfessorForm.controls['semester'].setValue(result.data.user.semester);
         this.departmentSelected = result.data.user.department;
         this.educationSelected = result.data.user.education;
         this.getCources(this.departmentSelected);
         this.courseSelected = result.data.user.course;
         this.getSubjects(this.courseSelected);
         this.subjectSelected = result.data.user.subject;
         this.batchSelected = result.data.user.batch;
         if(result.data.user.isSuspend){
            this.isSuspendTrue = true;
            this.isSuspendFalse = false;
            this.addProfessorForm.controls['isSuspend'].setValue(true);
         } else if(!result.data.user.isSusbend){
            this.isSuspendTrue = false;
            this.isSuspendFalse = true;
            this.addProfessorForm.controls['isSuspend'].setValue(false);
         }

         if(result.data.user.isActive){
          this.isActiveTrue = true;
          this.isActiveFalse = false;
          this.addProfessorForm.controls['isActive'].setValue(true);
          } else if(!result.data.user.isActive){
              this.isActiveTrue = false;
              this.isActiveFalse = true;
              this.addProfessorForm.controls['isActive'].setValue(false);
          }         
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onSuspendRadioChange(value){
    if(value == 'isSuspendTrue'){
      this.isSuspendTrue = true;
      this.isSuspendFalse = false;
      this.addProfessorForm.controls['isSuspend'].setValue(true);
    }else{
      this.isSuspendTrue = false;
      this.isSuspendFalse = true;
      this.addProfessorForm.controls['isSuspend'].setValue(false);
    }
  }

  onActiveRadioChange(value){
    if(value == 'isActiveTrue'){
      this.isActiveTrue = true;
      this.isActiveFalse = false;
      this.addProfessorForm.controls['isActive'].setValue(true);
    }else{
      this.isActiveTrue = false;
      this.isActiveFalse = true;
      this.addProfessorForm.controls['isActive'].setValue(false);
    }
  }

  onDepartmentSelected(event){
    this.subjectSelected = null;
    this.courseSelected = null;
    this.departmentSelected = event;
    this.addProfessorForm.controls['department'].setValue(this.departmentSelected);
    this.getCources(this.departmentSelected);
  }

  onEducationSelected(event){
    this.educationSelected = event;
    this.addProfessorForm.controls['education'].setValue(this.educationSelected);
  }

  onCourseSelected(event){
    this.subjectSelected = null;
    this.courseSelected = event;
    this.addProfessorForm.controls['course'].setValue(this.courseSelected);
    this.getSubjects(this.courseSelected);
  }

  onSubjectSelected(event){
    this.subjectSelected = event;
    this.addProfessorForm.controls['subject'].setValue(this.subjectSelected);
  }

  onBatchSelected(event){
    this.batchSelected = event;
    this.addProfessorForm.controls['batch'].setValue(this.batchSelected);
  }

  getSemesters(){
    this.semesters = Array<Item>();
    this.semesters.push(new Item('Semester-1', 'Semester-1'));
    this.semesters.push(new Item('Semester-2', 'Semester-2'));
    this.semesters.push(new Item('Semester-3', 'Semester-3'));
    this.semesters.push(new Item('Semester-4', 'Semester-4'));
    this.semesters.push(new Item('Semester-5', 'Semester-5'));
    this.semesters.push(new Item('Semester-6', 'Semester-6'));
    this.semesters.push(new Item('Semester-7', 'Semester-7'));
    this.semesters.push(new Item('Semester-8', 'Semester-8'));
    this.semesters.push(new Item('Semester-9', 'Semester-9'));
    this.semesters.push(new Item('Semester-10', 'Semester-10'));
   }

   getEducations(){
    this.educations = Array<Item>();   
    this.educations.push(new Item('bachelorsDegree', 'Bachelors degree'));
    this.educations.push(new Item('mastersDegree', 'Masters degree'));
    this.educations.push(new Item('doctoralDegree', 'Doctoral degree'));
    this.educations.push(new Item('associateDegree', 'Associate degree'));
   }

   onSemesterSelected(event){
    const value = event.target.value;
    this.semesterSelected = value;
    this.addProfessorForm.controls['semester'].setValue(this.semesterSelected);
  }

  isEmailUnique(control: FormControl) {
    const data = {
      "email": this.addProfessorForm.controls['email'].value
    };
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        if(this.isEdit){
          resolve(null);
          return q;
        }
        
        this.accountService.emailExistWithRole(
          this.addProfessorForm.controls['email'].value,
          'admin'
          ).subscribe((result) => {
          if(result.status){
            resolve({ 'isEmailUnique': result.status });
          } else {
            resolve(null);
          }       
        });
      }, 1000);
    });
    return q;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getDepartments() {
    this.accountService.getDepartments()
      .subscribe((result: any) => {
        this.departments = Array<Item>();
        if (result.status) {    
          result.data.forEach((element, index) => {
            if(element.department && element.department.length > 0){
              element.department.forEach((itemElement, index) => {
                this.departments.push(new Item(itemElement._id, itemElement.departmentName));
              });              
            }
          });
        } else {
          this.departments = null;
          this.departmentSelected = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getCources(Id) {
    this.accountService.getCourcesByDepartments({ departments: Id})
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {    
          result.data.forEach((element, index) => {
            this.courses.push(new Item(element._id, element.name));
          });
        } else {
          this.courses = null;
          this.courseSelected = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getSubjects(Id) {
    this.accountService.getSubjectsByCourses({ courses: Id})
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {    
          result.data.forEach((element, index) => {
            this.subjects.push(new Item(element._id, element.name));
          });
        } else {
          this.subjects = null;
          this.subjectSelected = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getBatches() {
    this.accountService.getBatches()
      .subscribe((result: any) => {
        this.batches = Array<Item>();
        if (result.status) {    
          result.data.forEach((element, index) => {
            this.batches.push(new Item(element._id,`${element.name} (${element.startYear}-${element.endYear})`));
          });
        } else {
          this.batches = null;
          this.batchSelected = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickAddProfessor(){
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.addProfessorForm.invalid) {
      this.loading = false;
        return;
    }

    const  data = {
      "firstName": this.addProfessorForm.controls['firstName'].value,
      "lastName": this.addProfessorForm.controls['lastName'].value,
      "mobileNumber": this.addProfessorForm.controls['mobileNumber'].value,
      "alternateMobileNumber": this.addProfessorForm.controls['alternateMobileNumber'].value,
      "email": this.addProfessorForm.controls['email'].value,
      "password": this.addProfessorForm.controls['password'].value,
      "role": 'professor',
      "designation": this.addProfessorForm.controls['designation'].value,
      "department": this.addProfessorForm.controls['department'].value,
      "education": this.addProfessorForm.controls['education'].value,
      "semester": this.addProfessorForm.controls['semester'].value,
      "course": this.addProfessorForm.controls['course'].value,
      "subject": this.addProfessorForm.controls['subject'].value,
      "batch": this.addProfessorForm.controls['batch'].value,
      "isSuspend": this.addProfessorForm.controls['isSuspend'].value,
      "isActive": this.addProfessorForm.controls['isActive'].value,
    }

    if(!this.key){
      this.accountService.createUser(data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.goToProfessorPage();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      } else {
        this.accountService.editProfessorUser(this.key, data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.goToProfessorPage();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      }
  }

  goToProfessorPage(){
    this.router.navigate(['/professor']);
  }

  onClickShowPassword(){
    if(this.showPassword){
      this.showPassword = false;
    }else{
      this.showPassword = true;
    }    
  }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}