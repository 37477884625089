import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

// Super Admin
const NavigationItems = [
  {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard/default',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },      
        {
          id: 'university',
          title: 'Institutions',
          type: 'item',
          url: '/university',
          icon: 'feather icon-folder',
          classes: 'nav-item',
          children: [
            {
              id: 'add-university',
              title: 'Add Institutions',
              type: 'item',
              url: '/university/add-university',
            }           
          ]
        },
        {
          id: 'admin',
          title: 'Admins',
          type: 'item',
          url: '/admin-user',
          icon: 'feather icon-folder',
          classes: 'nav-item',
          children: [
            {
              id: 'add-admin',
              title: 'Add Admin',
              type: 'item',
              url: '/admin-user/add-admin-user',
            }           
          ]
        },
        {
          id: 'course',
          title: 'Courses',
          type: 'item',
          url: '/course',
          icon: 'feather icon-folder',
          classes: 'nav-item',
          children: [
            {
              id: 'add-course',
              title: 'Add Course',
              type: 'item',
              url: '/course/add-course',
            }           
          ]
        },
        {
          id: 'subject',
          title: 'Subjects',
          type: 'item',
          url: '/subject',
          icon: 'feather icon-folder',
          classes: 'nav-item',
          children: [
            {
              id: 'add-subject',
              title: 'Add Subject',
              type: 'item',
              url: '/subject/add-subject',
            }           
          ]
        },
        {
          id: 'unit',
          title: 'Modules',
          type: 'item',
          url: '/module',
          icon: 'feather icon-folder',
          classes: 'nav-item',
          children: [
            {
              id: 'add-module',
              title: 'Add Module',
              type: 'item',
              url: '/module/add-module',
            },
            {
              id: 'topic',
              title: 'Topics',
              type: 'item',
              url: '/module/topic?key=5ec8a6fdbce144278c8ac445',
              key: '5ec8a6fdbce144278c8ac445',
              children: [
                {
                  id: 'add-content',
                  title: 'Add Content',
                  type: 'item',
                  url: '/module/topic/addcontent',
                },
                {
                  id: 'content',
                  title: 'Content',
                  type: 'item',
                  url: '/module/topic/content?key=5ec8af7972247f2d889c4e54',
                  key: '5ec8af7972247f2d889c4e54',
                },
                {
                  id: 'content',
                  title: 'Content',
                  type: 'item',
                  url: '/module/topic/content?key=5ec8b01272247f2d889c4e55',
                  key: '5ec8b01272247f2d889c4e55',
                }
              ]
            },
            {
              id: 'topic',
              title: 'Topics',
              type: 'item',
              url: '/module/topic?key=5ec8a75ada6e7d2a1c94a0cc',
            }             
          ]
        }
      ]        
  }]

  // Admin
  const AdminNavigationItems = [
    {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: 'feather icon-home',
            classes: 'nav-item',
          },
          {
            id: 'batch',
            title: 'Batch',
            type: 'item',
            url: '/batch',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-batch',
                title: 'Add Batch',
                type: 'item',
                url: '/batch/add-batch',
              }           
            ]
          },
          {
            id: 'student',
            title: 'Students',
            type: 'item',
            url: '/student',
            icon: 'feather icon-folder',
            classes: 'nav-item'
          },
          {
            id: 'professor',
            title: 'Faculties',
            type: 'item',
            url: '/professor',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-professor',
                title: 'Add Faculty',
                type: 'item',
                url: '/professor/add-professor',
              }           
            ]
          },
          {
            id: 'assign-professor',
            title: 'Assigned Faculties',
            type: 'item',
            url: '/assign-professor',
            icon: 'feather icon-folder',
            classes: 'nav-item'
          },
          {
            id: 'course',
            title: 'Courses',
            type: 'item',
            url: '/course',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-course',
                title: 'Add Course',
                type: 'item',
                url: '/course/add-course',
              }           
            ]
          },
          {
            id: 'subject',
            title: 'Subjects',
            type: 'item',
            url: '/subject',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-subject',
                title: 'Add Subject',
                type: 'item',
                url: '/subject/add-subject',
              }           
            ]
          },
          {
            id: 'unit',
            title: 'Modules',
            type: 'item',
            url: '/module',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-module',
                title: 'Add Module',
                type: 'item',
                url: '/module/add-module',
              },
              {
                id: 'topic',
                title: 'Topics',
                type: 'item',
                url: '/module/topic?key=5ec8a6fdbce144278c8ac445',
                key: '5ec8a6fdbce144278c8ac445',
                children: [
                  {
                    id: 'add-content',
                    title: 'Add Content',
                    type: 'item',
                    url: '/module/topic/addcontent',
                  },
                  {
                    id: 'content',
                    title: 'Content',
                    type: 'item',
                    url: '/module/topic/content?key=5ec8af7972247f2d889c4e54',
                    key: '5ec8af7972247f2d889c4e54',
                  },
                  {
                    id: 'content',
                    title: 'Content',
                    type: 'item',
                    url: '/module/topic/content?key=5ec8b01272247f2d889c4e55',
                    key: '5ec8b01272247f2d889c4e55',
                  }
                ]
              },
              {
                id: 'topic',
                title: 'Topics',
                type: 'item',
                url: '/module/topic?key=5ec8a75ada6e7d2a1c94a0cc',
              }             
            ]
          }
        ]        
    }]

  // Teacher 
  const TeacherNavigationItems = [
    {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: 'feather icon-home',
            classes: 'nav-item',
          },
          {
            id: 'subject',
            title: 'Subjects',
            type: 'item',
            url: '/subject',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-subject',
                title: 'Add Subject',
                type: 'item',
                url: '/subject/add-subject',
              }           
            ]
          },
          {
            id: 'unit',
            title: 'Modules',
            type: 'item',
            url: '/module',
            icon: 'feather icon-folder',
            classes: 'nav-item',
            children: [
              {
                id: 'add-module',
                title: 'Add Module',
                type: 'item',
                url: '/module/add-module',
              },
              {
                id: 'topic',
                title: 'Topics',
                type: 'item',
                url: '/module/topic?key=5ec8a6fdbce144278c8ac445',
                key: '5ec8a6fdbce144278c8ac445',
                children: [
                  {
                    id: 'add-content',
                    title: 'Add Content',
                    type: 'item',
                    url: '/module/topic/addcontent',
                  },
                  {
                    id: 'content',
                    title: 'Content',
                    type: 'item',
                    url: '/module/topic/content?key=5ec8af7972247f2d889c4e54',
                    key: '5ec8af7972247f2d889c4e54',
                  },
                  {
                    id: 'content',
                    title: 'Content',
                    type: 'item',
                    url: '/module/topic/content?key=5ec8b01272247f2d889c4e55',
                    key: '5ec8b01272247f2d889c4e55',
                  }
                ]
              },
              {
                id: 'topic',
                title: 'Topics',
                type: 'item',
                url: '/module/topic?key=5ec8a75ada6e7d2a1c94a0cc',
              }             
            ]
          }
        ]        
    }]

// const NavigationItems = [
//   {
//       id: 'navigation',
//       title: 'Navigation',
//       type: 'group',
//       icon: 'icon-navigation',
//       children: [
//         {
//           id: 'dashboard',
//           title: 'B Com',
//           type: 'item',
//           url: '/dashboard/default',
//           icon: 'feather icon-home',
//           classes: 'nav-item',
//         }
//       ]
//     },
//     {
//       id: 'cource',
//       title: 'Accountancy',
//       type: 'group',
//       icon: 'icon-ui',
//       children: [
//         {
//           id: 'module',
//           title: 'Chapter 1',
//           type: 'collapse',
//           icon: 'feather icon-box',
//           url: '/module',
//           children: [
//           {
//             id: 'topic',
//             title: 'Meaning and Characteristics of Not-for-Profit Organisation',
//             type: 'item',
//             url: '/module/topic',
//             children: [
//               {
//                 id: 'content1',
//                 title: 'Content',
//                 type: 'item',
//                 url: '/module/topic/content?key=content',
//               },
//               {
//                 id: 'content2',
//                 title: 'Video',
//                 type: 'item',
//                 url: '/module/topic/content?key=video',
//               },
//               {
//                 id: 'content3',
//                 title: 'PDF',
//                 type: 'item',
//                 url: '/module/topic/content?key=pdf',
//               },
//               {
//                 id: 'content',
//                 title: 'Add Content',
//                 type: 'item',
//                 url: '/module/topic/addcontent',
//               }
//             ]
//           },
//           {
//             id: 'topic1',
//             title: 'Accounting Records of Not-for-Profit Organisations',
//             type: 'item',
//             url: '/module/topic?key=1'
//           },
//           {
//             id: 'topic2',
//             title: 'Receipt and Payment Account',
//             type: 'item',
//             url: '/module/topic?key=2'
//           },
//           {
//             id: 'topic3',
//             title: 'Income and Expenditure Account',
//             type: 'item',
//             url: '/module/topic?key=3'
//           }
//           ]
//         }
//     ]
//   }]

// const NavigationItems = [
// {
//     id: 'navigation',
//     title: 'Navigation',
//     type: 'group',
//     icon: 'icon-navigation',
//     children: [
//       {
//         id: 'dashboard',
//         title: 'B Com',
//         type: 'item',
//         url: '/dashboard/default',
//         icon: 'feather icon-home',
//         classes: 'nav-item',
//       }
//     ]
//   },
//   {
//     id: 'cource',
//     title: 'Accountancy',
//     type: 'group',
//     icon: 'icon-ui',
//     children: [
//       {
//         id: 'module',
//         title: 'Module 1',
//         type: 'collapse',
//         icon: 'feather icon-box',
//         url: '/module',
//         children: [
//         {
//           id: 'topic',
//           title: 'Topic 1',
//           type: 'item',
//           url: '/module/topic',
//           children: [
//             {
//               id: 'content',
//               title: 'Content',
//               type: 'item',
//               url: '/module/topic/content',
//             }
//           ]
//         },
//         {
//           id: 'badges',
//           title: 'Topic 2',
//           type: 'item',
//           url: '/module/topic'
//         }
//       ]
//     }
//   ]
// }]

// const NavigationItems = [
//   {
//     id: 'navigation',
//     title: 'Navigation',
//     type: 'group',
//     icon: 'icon-navigation',
//     children: [
//       {
//         id: 'dashboard',
//         title: 'Dashboard',
//         type: 'item',
//         url: '/dashboard/default',
//         icon: 'feather icon-home',
//         classes: 'nav-item',
//       }
//     ]
//   },
//   {
//     id: 'ui-element',
//     title: 'UI ELEMENT',
//     type: 'group',
//     icon: 'icon-ui',
//     children: [
//       {
//         id: 'basic',
//         title: 'Component',
//         type: 'collapse',
//         icon: 'feather icon-box',
//         children: [
//           {
//             id: 'button',
//             title: 'Button',
//             type: 'item',
//             url: '/basic/button'
//           },
//           {
//             id: 'badges',
//             title: 'Badges',
//             type: 'item',
//             url: '/basic/badges'
//           },
//           {
//             id: 'breadcrumb-pagination',
//             title: 'Breadcrumb & Pagination',
//             type: 'item',
//             url: '/basic/breadcrumb-paging'
//           },
//           {
//             id: 'collapse',
//             title: 'Collapse',
//             type: 'item',
//             url: '/basic/collapse'
//           },
//           {
//             id: 'tabs-pills',
//             title: 'Tabs & Pills',
//             type: 'item',
//             url: '/basic/tabs-pills'
//           },
//           {
//             id: 'typography',
//             title: 'Typography',
//             type: 'item',
//             url: '/basic/typography'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     id: 'forms',
//     title: 'Forms & Tables',
//     type: 'group',
//     icon: 'icon-group',
//     children: [
//       {
//         id: 'forms-element',
//         title: 'Form Elements',
//         type: 'item',
//         url: '/forms/basic',
//         classes: 'nav-item',
//         icon: 'feather icon-file-text'
//       },
//       {
//         id: 'tables',
//         title: 'Tables',
//         type: 'item',
//         url: '/tables/bootstrap',
//         classes: 'nav-item',
//         icon: 'feather icon-server'
//       }
//     ]
//   },
//   {
//     id: 'chart-maps',
//     title: 'Chart & Maps',
//     type: 'group',
//     icon: 'icon-charts',
//     children: [
//       {
//         id: 'charts',
//         title: 'Charts',
//         type: 'item',
//         url: '/charts/morris',
//         classes: 'nav-item',
//         icon: 'feather icon-pie-chart'
//       }
//     ]
//   },
//   {
//     id: 'pages',
//     title: 'Pages',
//     type: 'group',
//     icon: 'icon-pages',
//     children: [
//       {
//         id: 'auth',
//         title: 'Authentication',
//         type: 'collapse',
//         icon: 'feather icon-lock',
//         children: [
//           {
//             id: 'signup',
//             title: 'Sign up',
//             type: 'item',
//             url: '/auth/signup',
//             target: true,
//             breadcrumbs: false
//           },
//           {
//             id: 'signin',
//             title: 'Sign in',
//             type: 'item',
//             url: '/auth/signin',
//             target: true,
//             breadcrumbs: false
//           }
//         ]
//       },
//       {
//         id: 'sample-page',
//         title: 'Sample Page',
//         type: 'item',
//         url: '/sample-page',
//         classes: 'nav-item',
//         icon: 'feather icon-sidebar'
//       },
//       {
//         id: 'disabled-menu',
//         title: 'Disabled Menu',
//         type: 'item',
//         url: 'javascript:',
//         classes: 'nav-item disabled',
//         icon: 'feather icon-power',
//         external: true
//       }
//     ]
//   }
// ];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}


@Injectable()
export class TeacherNavigationItem {
  get() {
    return TeacherNavigationItems;
  }
}

@Injectable()
export class AdminNavigationItem {
  get() {
    return AdminNavigationItems;
  }
}
