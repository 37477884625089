import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-editcontent',
  templateUrl: './editcontent.component.html',
  styleUrls: ['./editcontent.component.scss']
})
export class EditcontentComponent implements OnInit {

  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  @ViewChild('apkaEditor') apkaEditor: any;
  contentTypes = [];
  public Editor = DecoupledEditor;
  contentType: Number;
  editorData

  unitId;
  contentId;
  content;

  isContent: boolean;
  isVideo: boolean;
  isPdf: boolean;

  editContentForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(private route: ActivatedRoute, 
    private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router) { 
    this.route.params.subscribe(params => {
      this.contentId = params['cid'];
      this.unitId = params['id'];
    });
  }

  ngOnInit(): void {
    this.getContentsById();
    this.contentTypes = this.getContentTypes();
    this.editContentForm = this.formBuilder.group({
      contentType: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: [''],
      pdfFile: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editContentForm.controls; }

  getContentTypes() {
    return [
      { id: '1', name: 'Content', value: 'text' },
      { id: '2', name: 'Video', value: 'video' },
      { id: '3', name: 'PDF', value: 'pdf' }
    ];
  }

  public onChange( { editor }: ChangeEvent ) {
    this.editorData = this.apkaEditor.editorInstance.getData();
  }

  onReady($event){
    $event.ui.getEditableElement().parentElement.insertBefore(
      $event.ui.view.toolbar.element,
      $event.ui.getEditableElement()
    );
    // $event.plugins.get('FileRepository').createUploadAdapter = function (loader) {
    //   return new UploadAdapter(loader);
    // };
  }

  getContentsById() {
    this.accountService.getContentById(this.contentId)
      .subscribe((result: any) => {
       
        if (result.status) { 
          this.content = result.data; 
          switch(this.content.contentType) { 
              case 'text': { 
                this.isContent = true;
                this.contentType = 1;
                this.editContentForm.controls['contentType'].setValue(this.contentType);
                this.editorData = this.content.data;
                break; 
              } 
              case 'video': { 
                this.isVideo = true;
                this.contentType = 2;
                this.editContentForm.controls['contentType'].setValue(this.contentType); 
                this.editContentForm.addControl('videoUrl', new FormControl('', [Validators.required, Validators.pattern(this.urlRegex)]));
                this.editContentForm.controls['pdfFile'].clearValidators();
                this.editContentForm.controls['videoUrl'].setValue(this.content.data); 
                break; 
              } 
              case 'pdf': { 
                this.isPdf = true;
                this.contentType = 3;
                this.editContentForm.controls['contentType'].setValue(this.contentType); 
                break; 
              } 
              default: { 
                this.isContent = true;
                this.contentType = 1;
                break; 
              } 
          } 
          
          this.editContentForm.controls['title'].setValue(this.content.title); 
          this.editContentForm.controls['description'].setValue(this.content.description); 
        } else {
          this.content = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  ddlChange(selectedValue) {
    this.contentType = selectedValue;
    this.editContentForm.controls['contentType'].setValue(selectedValue); 
    if(this.contentType == 2){
      this.editContentForm.addControl('videoUrl', new FormControl('', [Validators.required, Validators.pattern(this.urlRegex)]));
      this.editContentForm.controls['pdfFile'].clearValidators();
    } else if(this.contentType == 3){
      this.editContentForm.controls['pdfFile'].setValidators([Validators.required]);
      this.editContentForm.removeControl('videoUrl');
    } else {
      this.editContentForm.controls['pdfFile'].clearValidators();
      this.editContentForm.removeControl('videoUrl');
    }
    this.editContentForm.updateValueAndValidity();
  }

  onClickUpdate(){
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.editContentForm.invalid) {
      this.loading = false;
        return;
    }

    if(this.contentType == 1 && !this.editorData){
      this.loading = false;
      this.toastr.error('Content Cannot Empty', 'Error', {
        timeOut: 3000
      });
      return;
    }

    let  data;
    if(this.contentType == 1){
      data = {
        "unitId": this.unitId,
        "contentType": this.getContentTypes().find(x=>x.id == this.editContentForm.controls['contentType'].value).value ,
        "title": this.editContentForm.controls['title'].value,
        "description": this.editContentForm.controls['description'].value,
        "data": this.editorData
      }
    } else if(this.contentType == 2){
      data = {
        "unitId": this.unitId,
        "contentType": this.getContentTypes().find(x=>x.id == this.editContentForm.controls['contentType'].value).value ,
        "title": this.editContentForm.controls['title'].value,
        "description": this.editContentForm.controls['description'].value,
        "data": this.editContentForm.controls['videoUrl'].value
      }
    } else if(this.contentType == 3){

      // if(!this.isFileUploaded){
      //   this.toastr.error('Please Upload PDF File', 'Error', {
      //     timeOut: 3000
      //   });
      //   return;
      // }
      // data = {
      //   "unitId": this.key,
      //   "contentType": this.getContentTypes().find(x=>x.id == this.addContentForm.controls['contentType'].value).value ,
      //   "title": this.addContentForm.controls['title'].value,
      //   "description": this.addContentForm.controls['description'].value,
      //   "data": this.filePath
      // }
    }
    
    
    this.accountService.editContent(this.contentId, data)
        .subscribe((result) => {
            if(result.status){
              this.toastr.success(result.msg, 'Success', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
              // this.FileCount = 0;
              // this.fileName = "Choose file"; 
              this.router.navigate([`module/topic/${this.unitId}`]);
            }else{
              this.toastr.error('Error occured please try again', 'Error', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
            }
      }, err => { 
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
        this.loading = false;
        this.submitted = false;
     });

  }

  goToTopic(){
    this.router.navigateByUrl(`/module/topic/${this.unitId}`)
  }
}
