import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddcontentComponent } from './addcontent.component';


const routes: Routes = [
  {
    path: '',
    component: AddcontentComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddcontentRoutingModule { }
