import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  courses: Array<Item>;

  loading = false;

  deleteShowModal = false;
  deleteID;

  isAdmin = false;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    
    if(this.authService.getRole() == 'admin'){
      this.getCoursesByAdmin();
    }else{
      this.getCourses();
    }    
  }

  getCourses() {
    this.accountService.getCourses()
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.courses.push(new Item(element._id, element.name, element.universityId.name));
          });
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getCoursesByAdmin() {
    this.accountService.getCoursesByAdmin()
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.courses.push(new Item(element._id, element.name, element.universityId.name));
          });
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickEdit(id){
    this.router.navigate([`course/add-course/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteID = id;   
  }

  hide(){
    this.deleteShowModal = false;
  }

  onClickDeleteAction(){
    this.loading = true;
    this.accountService.deleteCourse(this.deleteID)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.getCourses();
      }else{
        this.toastr.error('Delete Course Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
      this.deleteShowModal = false;
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      this.deleteShowModal = false;
    });
  }
  
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string, universityName:string) {
        this.id = id;
        this.name = name;
        this.universityName = universityName;
    }

    id:string;
    name:string;
    universityName:string;
}
