<app-card cardTitle="Add Faculty" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
        <form [formGroup]="addProfessorForm">
            <div class="card-body text-center">
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">First Name</label>
                <input type="text" class="form-control" placeholder="First Name" id="firstName" name="firstName"
                formControlName="firstName" [ngClass]="{'is-invalid': f.firstName.invalid && (f.firstName.dirty || f.firstName.touched || submitted)}"/>
                <div *ngIf="!f.firstName.valid && (f.firstName.dirty || f.firstName.touched || f.firstName.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors?.required">First Name is required</div>
                </div> 
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" id="lastName" name="lastName"
                formControlName="lastName"/>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Mobile Number</label>
                <input type="text" class="form-control" placeholder="Mobile Number" id="mobileNumber" name="mobileNumber" (keypress)="numberOnly($event)"
                formControlName="mobileNumber" [ngClass]="{'is-invalid': f.mobileNumber.invalid && (f.mobileNumber.dirty || f.mobileNumber.touched || submitted)}"/>
                <div *ngIf="!f.mobileNumber.valid && (f.mobileNumber.dirty || f.mobileNumber.touched || f.mobileNumber.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.mobileNumber.errors && !f.mobileNumber.errors?.required">Mobile Number minimum 10 number</div>
                  <div *ngIf="f.mobileNumber.errors?.required">Mobile Number is required</div>
                </div>
              </div>
              <div class="from-group mb-3 w-75">
                <label for="universityName" class="float-left">Alternate Mobile Number</label>
                <input type="text" class="form-control" placeholder="Alternate Mobile Number" id="alternateMobileNumber" name="alternateMobileNumber" (keypress)="numberOnly($event)"
                formControlName="alternateMobileNumber" />
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Email</label>
                <input type="email" class="form-control" placeholder="Email" id="email" name="email" [attr.disabled]="isEdit === true ? 'disabled' : null"
                formControlName="email" [ngClass]="{'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched || submitted)}"/>
                <div *ngIf="!f.email.valid && (f.email.dirty || f.email.touched || f.email.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.email.errors?.required">Email is required</div>
                  <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
                  <div *ngIf="f.email.hasError('isEmailUnique')">Email already exist, please try another one</div>
                </div> 
              </div>
              <div class="form-group mb-3 w-75 position-relative">
                <label for="universityName" class="float-left">Password</label>
                <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password" id="password" name="password" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <i style="top:43px;right:15px;cursor: pointer;" [class]="showPassword ? 'position-absolute feather icon-eye' : 'position-absolute feather icon-eye-off'" (click)="onClickShowPassword()"></i>
                <div class="invalid-feedback">
                  <div *ngIf="f.password.errors?.required">Password is required</div>
                  <div *ngIf="f.password.errors?.minlength">Password must be at least 8 characters</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Designation</label>
                <input type="text" class="form-control" placeholder="Designation" id="designation" name="designation"
                formControlName="designation" [ngClass]="{'is-invalid': f.designation.invalid && (f.designation.dirty || f.designation.touched || submitted)}"/>
                <div *ngIf="!f.designation.valid && (f.designation.dirty || f.designation.touched || f.designation.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.designation.errors?.required">Designation is required</div>
                </div> 
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Department</label>
                <ng-select 
                #department   
                bindLabel="department"
                placeholder="Select departments"
                appendTo="body"
                multiple="true"
                [searchable]="false" 
                [clearable]="true"
                [(ngModel)]="departmentSelected"
                [ngClass]="{'is-invalid': !f.department.valid && (f.department.dirty || f.department.touched || submitted)}"
                id="department" name="department" formControlName="department" required (change)='onDepartmentSelected($event)' >
                  <ng-option [value]="item.id" *ngFor="let item of departments">
                      {{item.name}}
                  </ng-option>
                </ng-select>
                <div *ngIf="!f.department.valid && (f.department.dirty || f.department.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.department.errors?.required">Department is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Semester</label>
                <select class="form-control" (change)='onSemesterSelected($event)'
                id="semester"name="semester" formControlName="semester" 
                [ngClass]="{'is-invalid': !f.semester.valid && (f.semester.dirty || f.semester.touched || submitted)}">
                  <option *ngFor="let item of semesters" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.semester.valid && (f.semester.dirty || f.semester.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.semester.errors?.required">Semester is required</div>
                </div>
              </div>
              <!-- <div class="form-group mb-3 w-75">
                <input type="text" class="form-control" placeholder="Designation" id="designation" name="designation"
                formControlName="designation" [ngClass]="{'is-invalid': f.designation.invalid && (f.designation.dirty || f.designation.touched || submitted)}"/>
                <div *ngIf="!f.designation.valid && (f.designation.dirty || f.designation.touched || f.designation.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.designation.errors?.required">Designation is required</div>
                </div> 
              </div> -->
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Education</label>
                <ng-select 
                #education   
                bindLabel="education"
                placeholder="Select Educations"
                appendTo="body"
                multiple="true"
                [searchable]="false" 
                [clearable]="true"
                [(ngModel)]="educationSelected"
                [ngClass]="{'is-invalid': !f.education.valid && (f.education.dirty || f.education.touched || submitted)}"
                id="education" name="education" formControlName="education" required (change)='onEducationSelected($event)' >
                  <ng-option [value]="item.id" *ngFor="let item of educations">
                      {{item.name}}
                  </ng-option>
                </ng-select>
                <div *ngIf="!f.education.valid && (f.education.dirty || f.education.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.education.errors?.required">Education is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Course</label>
                <ng-select 
                #course   
                bindLabel="course"
                placeholder="Select Courses"
                appendTo="body"
                multiple="true"
                [searchable]="false" 
                [clearable]="true"
                [(ngModel)]="courseSelected"
                [ngClass]="{'is-invalid': !f.course.valid && (f.course.dirty || f.course.touched || submitted)}"
                id="course" name="course" formControlName="course" required (change)='onCourseSelected($event)' >
                  <ng-option [value]="item.id" *ngFor="let item of courses">
                      {{item.name}}
                  </ng-option>
                </ng-select>
                <div *ngIf="!f.course.valid && (f.course.dirty || f.course.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.course.errors?.required">Course is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Subject</label>
                <ng-select 
                #subject   
                bindLabel="subject"
                placeholder="Select Subjects"
                appendTo="body"
                multiple="true"
                [searchable]="false" 
                [clearable]="true"
                [(ngModel)]="subjectSelected"
                [ngClass]="{'is-invalid': !f.subject.valid && (f.subject.dirty || f.subject.touched || submitted)}"
                id="subject" name="subject" formControlName="subject" required (change)='onSubjectSelected($event)' >
                  <ng-option [value]="item.id" *ngFor="let item of subjects">
                      {{item.name}}
                  </ng-option>
                </ng-select>
                <div *ngIf="!f.subject.valid && (f.subject.dirty || f.subject.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.subject.errors?.required">Subject is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Batch</label>
                <ng-select 
                #batch   
                bindLabel="batch"
                placeholder="Select Batches"
                appendTo="body"
                multiple="true"
                [searchable]="false" 
                [clearable]="true"
                [(ngModel)]="batchSelected"
                [ngClass]="{'is-invalid': !f.batch.valid && (f.batch.dirty || f.batch.touched || submitted)}"
                id="batch" name="batch" formControlName="batch" required (change)='onBatchSelected($event)' >
                  <ng-option [value]="item.id" *ngFor="let item of batches">
                      {{item.name}}
                  </ng-option>
                </ng-select>
                <div *ngIf="!f.batch.valid && (f.batch.dirty || f.batch.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.batch.errors?.required">Batch is required</div>
                </div>
              </div>
              <div *ngIf="isEdit" class="form-group mb-3 w-75 text-left">
                <div class="form-check-inline">
                    <label class="form-check-label">
                      Suspend
                    </label>
                  </div>
                <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isSuspend" [checked]="isSuspendTrue" (change)="onSuspendRadioChange('isSuspendTrue')"> True
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isSuspend" [checked]="isSuspendFalse" (change)="onSuspendRadioChange('isSuspendFalse')"> False
                    </label>
                  </div>
              </div>
              <div *ngIf="isEdit" class="form-group mb-3 w-75 text-left">
                <div class="form-check-inline">
                    <label class="form-check-label">
                      Active
                    </label>
                  </div>
                <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isActive" [checked]="isActiveTrue" (change)="onActiveRadioChange('isActiveTrue')"> True
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isActive" [checked]="isActiveFalse" (change)="onActiveRadioChange('isActiveFalse')"> False
                    </label>
                  </div>
              </div>
              <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/professor']">Cancel</button>
              <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddProfessor()">{{buttonText}}</button>
            </div>
          </form>
    </div>       
  </app-card>