import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditcontentRoutingModule } from './editcontent-routing.module';
import { EditcontentComponent } from './editcontent.component';
import { CardModule } from 'src/app/theme/shared/components';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [EditcontentComponent],
  imports: [
    CommonModule,
    EditcontentRoutingModule,
    CardModule,
    CKEditorModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [EditcontentComponent]
})
export class EditcontentModule { }
