import { Component, OnInit } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";

  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  pptUrl_ = '<iframe src="//docs.google.com/gview?url=https://s3.ap-south-1.amazonaws.com/apka-edu.com/uploadFiles/file_example_PPT_250kB.ppt&embedded=true" style="width:600px; height:500px;" frameborder="0"></iframe>';
  pptUrl: SafeResourceUrl;

  isContent: boolean;
  isVideo: boolean;
  isPdf: boolean;
  isPPT: boolean;

  key;
  content;

  constructor(private embedService: EmbedVideoService,
    private route: ActivatedRoute, private accountService: AccountService, private toastr: ToastrService,
    private router: Router, public sanitizer: DomSanitizer) {
    // this.route.queryParams.subscribe(params => {
    //   this.key = params['key'];
    // });    
   }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['cid'];
      this.key = id;
      });
    this.getContentsById();
  }

  getContentsById() {
    this.accountService.getContentById(this.key)
      .subscribe((result: any) => {
       
        if (result.status) { 
          this.content = result.data; 
          switch(this.content.contentType) { 
              case 'text': { 
                this.isContent = true;
                break; 
              } 
              case 'video': { 
                this.isVideo = true;
                this.iframe_html = this.embedService.embed(this.content.data, {
                  query: { portrait: 0, color: '333' },
                  attr: { width: 600, height: 400 }
                });
                break; 
              } 
              case 'pdf': { 
                this.isPdf = true;
                this.pdfSrc = this.content.data;
                break; 
              } 
              case 'ppt': { 
                this.isPPT = true;
                this.pptUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`//docs.google.com/gview?url=${this.content.data}&embedded=true`);
                break; 
              } 
              default: { 
                this.isContent = true;
                break; 
              } 
          } 
        } else {
          this.content = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickDelete(){

  }

  onClickEdit(){
    
  }

}
