import { Component, OnInit } from '@angular/core';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-batch',
  templateUrl: './add-batch.component.html',
  styleUrls: ['./add-batch.component.scss']
})
export class AddBatchComponent implements OnInit {

  universities: Array<Item>;
  universitySelected;

  addBatchForm: FormGroup;
  submitted = false;
  loading = false;

  key;
  buttonText
  university;

  isAcademicYear = true;
  isCalendarYear = false;
  
  constructor(public calendar: NgbCalendar,private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute,
    private authService: AuthService) { 
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
    }

  ngOnInit(): void {
    this.addBatchForm = this.formBuilder.group({
      university: ['', [Validators.required]],
      startYear: ['', [Validators.required]],
      endYear: ['', [Validators.required]]
    });

    if(this.key){
      this.buttonText = 'Update';
      this.getBatchById(this.key);
    }else{
      this.buttonText = 'Save';
      this.getAdminUniversities();   
    }
  }

   // convenience getter for easy access to form fields
   get f() { return this.addBatchForm.controls; }

   getBatchById(id) {
    this.accountService.getBatchById(id)
      .subscribe((result: any) => {
        if (result.status) {
         this.university = result.data.universityId;
         if(result.data.name == 'AcademicYear'){
          this.isAcademicYear = true;
          this.isCalendarYear = false;
        }else{
          this.isAcademicYear = false;
          this.isCalendarYear = true;
        }
         this.addBatchForm.controls['startYear'].setValue(`${result.data.startYear}-01`);
         this.addBatchForm.controls['endYear'].setValue(`${result.data.endYear}-01`);
         this.getAdminUniversities();
        } else {
          
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

   getAdminUniversities() {
    this.accountService.getAdminUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {          
          result.data.forEach((element,index) => {     
            if(this.key){
              this.universitySelected = this.university._id;
              this.addBatchForm.controls['university'].setValue(this.university._id);                   
             }else{
              if(index == 0){
                this.universitySelected = element._id;
                this.addBatchForm.controls['university'].setValue(element._id); 
              }   
             }
            this.universities.push(new Item(element._id, element.name));
          });
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onUniversitySelected(event) {
    const value = event.target.value;
    this.universitySelected = value;
    this.addBatchForm.controls['university'].setValue(this.universitySelected);
 }

   onClickAddBatch(){
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.addBatchForm.invalid) {
      this.loading = false;
        return;
    }

    const  data = {
      "universityId": this.addBatchForm.controls['university'].value,
      "name":  this.isAcademicYear == true ? 'AcademicYear' : 'CalendarYear',
      "startYear": this.addBatchForm.controls['startYear'].value.substring(0,4),
      "endYear": this.addBatchForm.controls['endYear'].value.substring(0,4)
    }

    if(!this.key){
      this.accountService.addBatch(data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.navigateService.goToBatch();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      } else {
        this.accountService.editBatch(this.key, data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.navigateService.goToBatch();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      }
      
   }

   onBatchRadioChange(value){
    if(value == 'isAcademicYear'){
      this.isAcademicYear = true;
      this.isCalendarYear = false;
    }else{
      this.isAcademicYear = false;
      this.isCalendarYear = true;
    }
   }

}


export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}
