<div class="row">
    <div class="col-xl-12">
      <app-card cardTitle="Faculties" [options]="false" blockClass="table-border-style">
        <div class="table-responsive">
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/professor/add-professor']">Add</button>
          <!-- Under Deveopment !... -->
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Mobile No</th>
              <th>Designation</th>
              <th>Suspend</th>
              <th>Active</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of professors;let i = index;" >
              <th scope="row">{{i+1}}</th>
              <td>{{item.email}}</td>
              <td>{{item.phoneNumer}}</td>
              <td>{{item.designation}}</td>
              <td>{{item.isSuspend}}</td>
              <td>{{item.isActive}}</td>
              <td><button class="btn btn-outline-primary shadow-2" (click)="onClickEdit(item.id)">Edit</button></td>
              <td><button class="btn btn-outline-primary shadow-2" [disabled]="loading" (click)="onClickDelete(item.id)" >Delete</button></td>
            </tr>
            </tbody>
          </table>
        </div>
      </app-card>      
    </div>
</div>

<!-- Add Modal -->
<div class="modal" id="myModal" [style.display]="deleteShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header" style="height: 50px;">
        <h4 class="modal-title">Delete Faculty</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <label>Are you sure you want to delete this faculty ?</label>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="height: 65px;">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Cancel</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="professorDelete()">Delete</button>
      </div>
    </div>
  </div>
</div>