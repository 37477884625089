import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './../app/services/auth.service';
import { ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { NavigateService } from './../app/services/navigate.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private navigateService: NavigateService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getAuthToken();
    
    let authReq;
    if (authToken) {
      if(req.url == '/user/logout'){
        authReq = req.clone({
          setHeaders: {
            'x-access-token': 'bearer ' + authToken,
            'x-refresh-token': 'bearer ' + this.auth.getAuthRefreshToken(),
            'Content-Type': 'application/json'
          }
        });
      } else if(req.url == '/content/upload'){
        authReq = req.clone({
          setHeaders: {
            'x-access-token': 'bearer ' + authToken,
          }
        });
      } else{
        authReq = req.clone({
          setHeaders: {
            'x-access-token': 'bearer ' + authToken,
            'Content-Type': 'application/json'
          }
        });
      }
    } else {
      authReq = req;
    }

    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          // this.errorDialogService.openDialog(event);

        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401) {
            if (error.error.message == "access token is missing or invalid") {
                //TODO: Token refreshing
                let params = {
                  token: authToken,
                  refreshToken: 'bearer ' + this.auth.getAuthRefreshToken()
                };
                req = req.clone({
                  setHeaders: {
                    'x-refresh-token': 'bearer ' + this.auth.getAuthRefreshToken(),
                    'Content-Type': 'application/json'
                  }
                });
                this.http.post('/user/accesstoken', params).subscribe( 
                  (data: any) => {
                    //If reload successful update tokens
                    if (data.status == 200) {
                      //Update tokens
                      this.auth.setJwt(data.result.token);
                      this.auth.setRJwt(data.result.refreshToken);
                      //Clone our fieled request ant try to resend it
                      req = req.clone({
                        setHeaders: {
                          'x-access-token': 'bearer ' + data.result.token
                        }
                      });
                      return next.handle(authReq).pipe(
                        catchError((error: HttpErrorResponse) => {
                          return throwError(error);
                        })
                      );
                    } else {
                      //Logout from account
                      this.auth.logout();
                      this.navigateService.goToSignIn();
                    }
                  }
                );
            } else {
                //Logout from account or do some other stuff
                this.auth.logout();
                this.navigateService.goToSignIn();
            }
        }
        return throwError(error);
      })
    );
  }
}
