import {Component, Input, OnInit} from '@angular/core';
import {NavigationItem} from '../../../layout/admin/navigation/navigation';
import {Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, ActivationEnd, ActivatedRouteSnapshot, Params} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { StudentNavigationItem } from 'src/app/theme/layout/admin/navigation/navigation-student';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: any;

  public breadcrumbs: IBreadCrumb[];

  constructor(private _router: Router, public nav: NavigationItem, private titleService: Title,
    private activatedRoute: ActivatedRoute, public snav: StudentNavigationItem) {
    this.navigation = this.nav.get();
    //this.setBreadcrumb();
    this.type = 'theme2';
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }


  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    
    // ... implementation of buildBreadCrumb
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    let params;
    let title = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if(isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
      params = route.snapshot.params;
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    let nextUrl = path ? `${url}/${path}` : url;

    // My Code
    if(nextUrl.includes(':id')){
      let urlSplit = this._router.url.split('/');
      if(urlSplit.length == 6){
        nextUrl = nextUrl.replace(':cid', urlSplit[5]);
      } 
      nextUrl = nextUrl.replace(':id', urlSplit[3]);
    }
    // My Code End

    const breadcrumb: IBreadCrumb = {
        label: label,
        url: nextUrl,
        params: params,
        title: title
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        //If we are not on our current path yet,
        //there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  setBreadcrumb() {
    let routerUrl: string;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        const activeLink = router.url;
        this.filterNavigation(activeLink);
      }
    });
  }

  filterNavigation(activeLink) {
    let result: any;
    let title = 'Welcome';
    this.navigation.forEach(function (a) {
      if (a.type === 'item' && 'url' in a && a.url === activeLink) {
        result = [{
          'url': ('url' in a) ? a.url : false,
          'title': a.title,
          'breadcrumbs': ('breadcrumbs' in a) ? a.breadcrumbs : true,
          'type': a.type
        }];
        title = a.title;
      } else {
        if ((a.type === 'group' || a.type === 'item') && 'children' in a) {
          a.children.forEach(function (b) {
            if ((b.type === 'item' || b.type ===  'collapse') && 'url' in b && b.url === activeLink) {
              result = [
                /*{
                'url': ('url' in a) ? a.url : false,
                'title': a.title,
                'breadcrumbs': ('breadcrumbs' in a) ? a.breadcrumbs : true,
                  'type': a.type
                },*/
                {
                'url': ('url' in b) ? b.url : false,
                'title': b.title,
                'breadcrumbs': ('breadcrumbs' in b) ? b.breadcrumbs : true,
                  'type': b.type
                }
              ];
              title = b.title;
            } else {
              if ((b.type === 'collapse' || b.type === 'item') && 'children' in b) {
                b.children.forEach(function (c) {
                  if (c.type === 'item' && 'url' in c && c.url === activeLink) {
                    result = [
                      /*{
                        'url': ('url' in a) ? a.url : false,
                        'title': a.title,
                        'breadcrumbs': ('breadcrumbs' in a) ? a.breadcrumbs : true,
                        'type': a.type
                      },*/
                      {
                        'url': ('url' in b) ? b.url : false,
                        'title': b.title,
                        'breadcrumbs': ('breadcrumbs' in b) ? b.breadcrumbs : true,
                        'type': b.type
                      },
                      {
                        'url': ('url' in c) ? c.url : false,
                        'title': c.title,
                        'breadcrumbs': ('breadcrumbs' in c) ? c.breadcrumbs : true,
                        'type': c.type
                      }
                    ];
                    title = c.title;
                  }  else {
                    if (c.type === 'item' && 'children' in c) {
                        c.children.forEach(function (e) {
                          if (e.type === 'item' && 'url' in e && e.url === activeLink) {
                            result = [
                              /*{
                                'url': ('url' in a) ? a.url : false,
                                'title': a.title,
                                'breadcrumbs': ('breadcrumbs' in a) ? a.breadcrumbs : true,
                                'type': a.type
                              },*/
                              {
                                'url': ('url' in b) ? b.url : false,
                                'title': b.title,
                                'breadcrumbs': ('breadcrumbs' in b) ? b.breadcrumbs : true,
                                'type': b.type
                              },
                              {
                                'url': ('url' in c) ? c.url : false,
                                'title': c.title,
                                'breadcrumbs': ('breadcrumbs' in c) ? c.breadcrumbs : true,
                                'type': c.type
                              },
                              {
                                'url': ('url' in e) ? e.url : false,
                                'title': e.title,
                                'breadcrumbs': ('breadcrumbs' in e) ? e.breadcrumbs : true,
                                'type': e.type
                              }
                            ];
                            title = e.title;
                          }
                        });
                    }
                  }
                });
              }
            } 
          });
        }
      }
    });
    this.navigationList = result;
    this.titleService.setTitle('Apka-Education');
  }

}

export interface IBreadCrumb {
  label: string;
  url: string;
  params: Params;
  title: string;
}