<app-card cardTitle="Add Admin User" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
        <form [formGroup]="addAdminForm">
            <div class="card-body text-center">
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Institution Name</label>
                  <select class="form-control" (change)='onUniversitySelected($event)'
                  id="university"name="university" formControlName="university" 
                  [ngClass]="{'is-invalid': !f.university.valid && (f.university.dirty || f.university.touched || submitted)}">
                    <option *ngFor="let item of universities" [value]="item.id" >
                      {{item.name}}
                    </option>
                  </select>
                  <div *ngIf="!f.university.valid && (f.university.dirty || f.university.touched || submitted)" class="invalid-feedback">
                    <div *ngIf="f.university.errors?.required">Institution is required</div>
                  </div>
                </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">First Name</label>
                <input type="text" class="form-control" placeholder="First Name" id="firstName" name="firstName"
                formControlName="firstName" [ngClass]="{'is-invalid': f.firstName.invalid && (f.firstName.dirty || f.firstName.touched || submitted)}"/>
                <div *ngIf="!f.firstName.valid && (f.firstName.dirty || f.firstName.touched || f.firstName.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors?.required">First Name is required</div>
                </div> 
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" id="lastName" name="lastName"
                formControlName="lastName"/>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Mobile Number</label>
                <input type="text" class="form-control" placeholder="Mobile Number" id="mobileNumber" name="mobileNumber" (keypress)="numberOnly($event)"
                formControlName="mobileNumber" [ngClass]="{'is-invalid': f.mobileNumber.invalid && (f.mobileNumber.dirty || f.mobileNumber.touched || submitted)}"/>
                <div *ngIf="!f.mobileNumber.valid && (f.mobileNumber.dirty || f.mobileNumber.touched || f.mobileNumber.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.mobileNumber.errors && !f.mobileNumber.errors?.required">Mobile Number minimum 10 number</div>
                  <div *ngIf="f.mobileNumber.errors?.required">Mobile Number is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Email Name</label>
                <input type="email" class="form-control" placeholder="Email" id="email" name="email" [attr.disabled]="isEdit === true ? 'disabled' : null"
                formControlName="email" [ngClass]="{'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched || submitted)}"/>
                <div *ngIf="!f.email.valid && (f.email.dirty || f.email.touched || f.email.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.email.errors?.required">Email is required</div>
                  <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
                  <div *ngIf="f.email.hasError('isEmailUnique')">Email already exist, please try another one</div>
                </div> 
              </div>
              <div class="form-group mb-3 w-75 position-relative">
                <label for="universityName" class="float-left">Password</label>
                <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password" id="password" name="password" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <i style="top:43px;right:15px;cursor: pointer;" [class]="showPassword ? 'position-absolute feather icon-eye' : 'position-absolute feather icon-eye-off'" (click)="onClickShowPassword()"></i>
                <div class="invalid-feedback">
                  <div *ngIf="f.password.errors?.required">Password is required</div>
                  <div *ngIf="f.password.errors?.minlength">Password must be at least 8 characters</div>
                </div>
              </div>
              <div *ngIf="isEdit" class="form-group mb-3 w-75 text-left">
                <div class="form-check-inline">
                    <label class="form-check-label">
                      Suspend
                    </label>
                  </div>
                <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isSuspend" [checked]="isSuspendTrue" (change)="onSuspendRadioChange('isSuspendTrue')"> True
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isSuspend" [checked]="isSuspendFalse" (change)="onSuspendRadioChange('isSuspendFalse')"> False
                    </label>
                  </div>
              </div>
              <div *ngIf="isEdit" class="form-group mb-3 w-75 text-left">
                <div class="form-check-inline">
                    <label class="form-check-label">
                      Active
                    </label>
                  </div>
                <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isActive" [checked]="isActiveTrue" (change)="onActiveRadioChange('isActiveTrue')"> True
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="isActive" [checked]="isActiveFalse" (change)="onActiveRadioChange('isActiveFalse')"> False
                    </label>
                  </div>
              </div>
              <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/admin-user']">Cancel</button>
              <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddAdmin()">{{buttonText}}</button>
            </div>
          </form>
    </div>       
  </app-card>