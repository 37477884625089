<div class="row">
    <div class="col-md-12 col-xl-12">
        <app-card [hidHeader]="true">
            <form [formGroup]="addContentForm">
                <div class="col-10">
                    <div class="form-group">
                        <label for="title">Content Type</label>
                        <select class="form-control w-50" formControlName="contentTypes" id="contentType"
                            (change)="ddlChange($event.target.value)" id="contentType" name="contentType"
                            formControlName="contentType"
                            [ngClass]="{'is-invalid': !f.contentType.valid && (f.contentType.dirty || f.contentType.touched || submitted)}">
                            <option *ngFor="let type of contentTypes; let i = index" [value]="contentTypes[i].id">
                                {{contentTypes[i].name}}
                            </option>
                        </select>
                        <div *ngIf="!f.contentType.valid && (f.contentType.dirty || f.contentType.touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="f.contentType.errors?.required">Content Type is required</div>
                        </div>
                    </div>
                    <div class="form-group w-50">
                        <label for="title">Title</label>
                        <input type="text" class="form-control" id="title" placeholder="Title" name="title"
                            formControlName="title"
                            [ngClass]="{'is-invalid': !f.title.valid && (f.title.dirty || f.title.touched || submitted)}">
                        <div *ngIf="!f.title.valid && (f.title.dirty || f.title.touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="f.title.errors?.required">Title is required</div>
                        </div>
                    </div>
                    <div class="form-group w-50">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="Description"
                            name="description" formControlName="description">
                    </div>
                    <div *ngIf="contentType==1" class="mb-2">
                        <label for="title">Content</label>
                        <ckeditor #apkaEditor 
                        [config]="{ fontSize:{options:[10,11,12,13,14,15,16,18,20]} }"
                        
                        [editor]="Editor" (change)="onChange($event)" (ready)="onReady($event)">
                        </ckeditor>
                    </div>
                    <div *ngIf="contentType==2" class="form-group w-50 mb-2">
                        <label for="videoUrl">Video Url</label>
                        <input type="text" class="form-control" id="videoUrl" placeholder="https://url" name="videoUrl"
                            formControlName="videoUrl"
                            [ngClass]="{'is-invalid': !f.videoUrl.valid && (f.videoUrl.dirty || f.videoUrl.touched || submitted)}">
                        <div *ngIf="!f.videoUrl.valid && (f.videoUrl.dirty || f.videoUrl.touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="f.videoUrl.errors?.required">Video Url is required</div>
                            <div *ngIf="f.videoUrl.errors?.pattern">Video Url is invalid</div>
                        </div>
                    </div>
                    <div *ngIf="contentType==3" class="form-group mb-2">
                        <div class="row">    
                            <div class="col-10">
                                <input type="file" class="custom-file-input" name="pdfFile" id="pdfFile"
                                
                                [ngClass]="{'is-invalid': !f.pdfFile.valid && (f.pdfFile.dirty || f.pdfFile.touched || submitted)}"
                                accept=".pdf"  (change)="onFileChange($event)">
                                <label class="custom-file-label" for="pdfFile">{{fileName}}</label>
                                <div *ngIf="!f.pdfFile.valid && (f.pdfFile.dirty || f.pdfFile.touched || submitted)"
                                class="invalid-feedback">
                                <div *ngIf="f.pdfFile.errors?.required">PDF Upload is required</div>
                            </div>
                            </div>    
                            <div class="col-2">
                                <button class="btn btn-outline-primary" [disabled]="uploadLoader" (click)="onFileUpload()">Upload</button>
                            </div>                    
                        </div>
                    </div>
                    <div *ngIf="contentType==4" class="form-group mb-2">
                        <div class="row">    
                            <div class="col-10">
                                <input type="file" class="custom-file-input" name="pptFile" id="pptFile"
                                
                                [ngClass]="{'is-invalid': !f.pptFile.valid && (f.pptFile.dirty || f.pptFile.touched || submitted)}"
                                accept=".ppt"  (change)="onFileChange($event)">
                                <label class="custom-file-label" for="pptFile">{{fileName}}</label>
                                <div *ngIf="!f.pptFile.valid && (f.pptFile.dirty || f.pptFile.touched || submitted)"
                                class="invalid-feedback">
                                <div *ngIf="f.pptFile.errors?.required">PPT Upload is required</div>
                            </div>
                            </div>    
                            <div class="col-2">
                                <button class="btn btn-outline-primary" [disabled]="uploadLoader" (click)="onFileUpload()">Upload</button>
                            </div>                    
                        </div>
                    </div>
                </div>
                <div class="float-right">
                    <button class="btn btn-outline-primary" (click)="onClickSave()">Save</button>
                    <button class="btn btn-outline-primary" (click)="goToTopic()">Cancel</button>
                </div>
            </form>
        </app-card>
    </div>
</div>