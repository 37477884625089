import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';
import { ContentComponent } from './content.component';
import { CardModule } from 'src/app/theme/shared/components';
import { EmbedVideoService } from 'ngx-embed-video';
import { HttpClientModule } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [ContentComponent],
  imports: [
    CommonModule,
    ContentRoutingModule,
    CardModule,
    HttpClientModule,
    PdfViewerModule,
    CKEditorModule
  ],
  exports: [ContentComponent],
  providers: [ EmbedVideoService ]
})
export class ContentModule { }
