import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfessorRoutingModule } from './professor-routing.module';
import { ProfessorComponent } from './professor.component';
import { CardModule } from '../theme/shared/components';

@NgModule({
  declarations: [ProfessorComponent],
  imports: [
    CommonModule,
    ProfessorRoutingModule,
    CardModule
  ],
  exports: [ProfessorComponent]
})
export class ProfessorModule { }
