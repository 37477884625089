import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { element } from 'protractor';

@Component({
  selector: 'app-assign-professor',
  templateUrl: './assign-professor.component.html',
  styleUrls: ['./assign-professor.component.scss']
})
export class AssignProfessorComponent implements OnInit {

  assignProfessorForm: FormGroup;
  submitted = false;

  list: Array<Item>;
  ShowModal = false;

  professorList;
  professors: Array<Item>;
  professorSelected;

  designation;
  education;
  email;
  firstName;
  mobileNumber;

  selectedId;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router,  private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getData();
    this.assignProfessorForm = this.formBuilder.group({
      professor: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.assignProfessorForm.controls; }

  getData() {
    this.accountService.getAssignProfessors()
      .subscribe((result: any) => {
        this.list = Array<Item>();
        if (result.status) {       
          result.data.forEach((element, index) => {
            this.list.push(new Item(
              element._id, 
              element.semesterId, 
              element.courseId.name, 
              element.name,              
              element.professorName, 
              element.professorName,
              element._id,));
          });
        } else {
          this.list = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickView(id){
    this.selectedId = id;
    let item = this.list.find(x=>x.id == id);
    this.getProfessorsBySubject(item.subjectId, item.professorId);
  }

  getProfessorsBySubject(id, professorId) {
    this.accountService.getProfessorsBySubject(id)
      .subscribe((result: any) => { 
        this.professors = Array<Item>();       
        if (result.status) {          
                 
          if (result.status) {   
            this.professorList = result.data;        
            result.data.forEach((element, index) => {
              this.professors.push(new Item(
                element._id, 
                element.firstName, 
                '', 
                '',              
                '', 
                '',
                ''));
            });
            if(professorId){
              this.professorSelected = professorId;
              this.assignProfessorForm.controls['professor'].setValue(this.professorSelected);
              let item  = this.professorList.find(x => x._id == this.professorSelected);
              this.designation = item.designation;
              this.education  = item.education;
              this.email  = item.email;
              this.firstName  = item.firstName;
              this.mobileNumber  = item.mobileNumber;
            }
          } else {
            this.professors = null;
          }
          this.ShowModal = true;
        } 
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onProfessorSelected(event) {
    const value = event.target.value;
    this.professorSelected = value;
    this.assignProfessorForm.controls['professor'].setValue(this.professorSelected);
    let item  = this.professorList.find(x => x._id == this.professorSelected);
    this.designation = item.designation;
    this.education  = item.education;
    this.email  = item.email;
    this.firstName  = item.firstName;
    this.mobileNumber  = item.mobileNumber;
 }

  hide(){
    this.ShowModal = false;
    this.professors = null;
    this.professorSelected = null;
    this.assignProfessorForm.controls['professor'].setValue(null);
    this.designation = null;
    this.education  = null;
    this.email = null;
    this.firstName  = null;
    this.mobileNumber = null;
  }

  onClickSave(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.assignProfessorForm.invalid) {
        return;
    }

    const  data = {
      "professor": this.assignProfessorForm.controls['professor'].value
    }

    this.accountService.editAssignProfessor(this.selectedId, data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.submitted = false;
                this.hide();
                this.getData();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.submitted = false;
       }); 
  }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, semester:string, courseName:string, subjectName:string, 
      professorName: string, professorId:string, subjectId:string) {
        this.id = id;
        this.semester = semester;
        this.courseName = courseName;
        this.subjectName = subjectName;
        this.subjectId = subjectId;
        this.professorName = professorName;
        this.professorId = professorId;
    }

    id:string;
    semester:string;
    courseName:string;
    subjectName:string;
    subjectId:string;
    professorName:string;
    professorId:string;
    
}