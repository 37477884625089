import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})

export class BatchComponent implements OnInit {

  batches: Array<Item>;

  loading = false;

  deleteShowModal = false;
  deleteID;

  isAdmin = false;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.getBatches();
  }

  getBatches() {
    this.accountService.getBatches()
      .subscribe((result: any) => {
        this.batches = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            let batchName = `${element.name}(${element.startYear}-${element.endYear})`
            this.batches.push(new Item(element._id, batchName, element.universityId.name));
          });
        } else {
          this.batches = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickEdit(id){
    this.router.navigate([`batch/add-batch/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteID = id;   
  }

  hide(){
    this.deleteShowModal = false;
  }

  onClickDeleteAction(){
    this.loading = true;
    this.accountService.deleteBatch(this.deleteID)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.getBatches();
      }else{
        this.toastr.error('Delete Batch Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
      this.deleteShowModal = false;
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      this.deleteShowModal = false;
    });
  }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string, universityName:string) {
        this.id = id;
        this.name = name;
        this.universityName = universityName;
    }

    id:string;
    name:string;
    universityName:string;
}
