import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddProfessorRoutingModule } from './add-professor-routing.module';
import { AddProfessorComponent } from './add-professor.component';
import { CardModule } from 'src/app/theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';

@NgModule({
  declarations: [AddProfessorComponent],
  imports: [
    CommonModule,
    AddProfessorRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule, //added here too
    NgSelectModule
  ],
  providers:[],
  exports: [AddProfessorComponent]
})
export class AddProfessorModule { }
