import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-add-subject',
  templateUrl: './add-subject.component.html',
  styleUrls: ['./add-subject.component.scss']
})
export class AddSubjectComponent implements OnInit {

  universities: Array<Item>;
  universitySelected;

  courses: Array<Item>;
  courseSelected;

  semesters: Array<Item>;
  semesterSelected;

  addSubjectForm: FormGroup;
  submitted = false;
  loading = false;

  key;
  buttonText
  course;
  university;
  semester;

  isTeacher = false;
  isAdmin = false;

  departments: Array<Item>;
  departmentSelected;
  department;

  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute, private authService: AuthService) {
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
     }

  ngOnInit(): void {
    if(this.authService.getRole() == 'professor'){
      this.isTeacher = true;
    } else if(this.authService.getRole() == 'admin'){
      this.isAdmin = true;
    }
    this.addSubjectForm = this.formBuilder.group({
      university: ['', [Validators.required]],
      department: ['', [Validators.required]],
      course: ['', [Validators.required]],
      semester: [''],
      subjectName: ['', [Validators.required]]
    });
    if(this.key){
      this.buttonText = 'Update';
      this.getSubjectById(this.key);
    }else{
      this.buttonText = 'Save';
      if(this.isAdmin){        
        this.getAdminUniversities();
      }else{
        this.getUniversities();
      }  
    }
    this.getSemesters();
  }

   // convenience getter for easy access to form fields
   get f() { return this.addSubjectForm.controls; }

   getSemesters(){
    this.semesters = Array<Item>();
    this.semesters.push(new Item('Semester-1', 'Semester-1'));
    this.semesters.push(new Item('Semester-2', 'Semester-2'));
    this.semesters.push(new Item('Semester-3', 'Semester-3'));
    this.semesters.push(new Item('Semester-4', 'Semester-4'));
    this.semesters.push(new Item('Semester-5', 'Semester-5'));
    this.semesters.push(new Item('Semester-6', 'Semester-6'));
    this.semesters.push(new Item('Semester-7', 'Semester-7'));
    this.semesters.push(new Item('Semester-8', 'Semester-8'));
    this.semesters.push(new Item('Semester-9', 'Semester-9'));
    this.semesters.push(new Item('Semester-10', 'Semester-10'));
   }

   getUniversities() {
     this.accountService.getUniversities()
       .subscribe((result: any) => {
         this.universities = Array<Item>();
         if (result.status) {          
           result.data.forEach((element,index) => {         
            if(index == 0){
              this.universitySelected = element._id;
              this.addSubjectForm.controls['university'].setValue(element._id);    
            }             
             this.universities.push(new Item(element._id, element.name));
           });
           if(this.key){
            this.universitySelected = this.university._id;
            this.addSubjectForm.controls['university'].setValue(this.university._id);    
           }else{
            if(this.isTeacher){
              this.universitySelected = this.authService.getUniverSity();
              this.addSubjectForm.controls['university'].setValue(this.universitySelected); 
            }
           }
           this.getDepartments(this.universitySelected);
         } else {
           this.universities = null;
         }
       }, err => {
         this.toastr.error(err.error.message, 'Error', {
           timeOut: 3000
         });
       });
   }


   getAdminUniversities() {
    this.accountService.getAdminUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {          
          result.data.forEach((element,index) => {     
            if(this.key){
              this.universitySelected = this.university._id;
              this.addSubjectForm.controls['university'].setValue(this.university._id);                   
             }else{
              if(index == 0){
                this.universitySelected = element._id;
                this.addSubjectForm.controls['university'].setValue(element._id); 
              }   
             }
            this.universities.push(new Item(element._id, element.name));
          });
          this.getDepartments(this.universitySelected);
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

   onUniversitySelected(event) {
     const value = event.target.value;
     this.universitySelected = value;
     this.addSubjectForm.controls['university'].setValue(this.universitySelected);
     this.getDepartments(this.universitySelected);
  }

  getDepartments(id) {
    this.accountService.getDepartmentsByUniversityId(id)
      .subscribe((result: any) => {
        this.departments = Array<Item>();
        if (result.status) {    
          result.data.department.forEach((element, index) => {
              if(this.key){
                this.departmentSelected = this.department.Id;
                this.addSubjectForm.controls['department'].setValue(this.departmentSelected);  
               }else{
                if(index == 0){
                  this.departmentSelected = element._id;
                  this.addSubjectForm.controls['department'].setValue(element._id);  
                }  
            }             
            this.departments.push(new Item(element._id, element.departmentName));
          });          
          this.getCourses(this.departmentSelected);
        } else {
          this.departments = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onDepartmentSelected(event){
    const value = event.target.value;
    this.departmentSelected = value;
    this.addSubjectForm.controls['department'].setValue(this.departmentSelected);
    this.getCourses(this.departmentSelected);
  }

  getCourses(id) {
    this.accountService.getCoursesByDepartmentId(id)
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {    
            if(this.key){
              this.courseSelected = this.course._id;
              this.addSubjectForm.controls['course'].setValue(this.course._id);    
             }else{
              if(index == 0){
                this.courseSelected = element._id;
                this.addSubjectForm.controls['course'].setValue(element._id);    
              }         
            }        
            this.courses.push(new Item(element._id, element.name));
          });
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getSubjectById(id) {
    this.accountService.getSubjectById(id)
      .subscribe((result: any) => {
        if (result.status) {
         this.course = result.data.courseId;
         this.university = result.data.courseId.universityId; 
         this.department = result.data.courseId.department; 
         this.semester = result.data.semesterId; 
         this.addSubjectForm.controls['university'].setValue(this.university._id);
         this.addSubjectForm.controls['department'].setValue(this.department._id);
         this.addSubjectForm.controls['semester'].setValue(this.semester);
         this.addSubjectForm.controls['subjectName'].setValue(result.data.name);
         if(this.isAdmin){        
          this.getAdminUniversities();
        }else{
          this.getUniversities();
        }  
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onCourseSelected(event){
    const value = event.target.value;
    this.courseSelected = value;
    this.addSubjectForm.controls['course'].setValue(this.courseSelected);
  }

  onSemesterSelected(event){
    const value = event.target.value;
    this.semesterSelected = value;
    this.addSubjectForm.controls['semester'].setValue(this.semesterSelected);
  }

  onClickAddSubject(){

     this.submitted = true;
     this.loading = true;
 
     // stop here if form is invalid
     if (this.addSubjectForm.invalid) {
       this.loading = false;
         return;
     }

     if(this.universities.length == 0){
      this.toastr.error('Please Select University', 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      return;
    }

    if(this.courses.length == 0){
      this.toastr.error('Please Select Course', 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      return;
    }
 
     const  data = {
       "universityId": this.addSubjectForm.controls['university'].value,
       "courseId": this.addSubjectForm.controls['course'].value,
       "name": this.addSubjectForm.controls['subjectName'].value,
       "semesterId": this.addSubjectForm.controls['semester'].value,
     }
 
     if(!this.key){
     this.accountService.addSubject(data)
         .subscribe((result) => {
             if(result.status){
               this.toastr.success(result.msg, 'Success', {
                 timeOut: 3000
               });
               this.loading = false;
               this.submitted = false;
               this.navigateService.goToSubject();
             }else{
               this.toastr.error('Error occured please try again', 'Error', {
                 timeOut: 3000
               });
               this.loading = false;
               this.submitted = false;
             }
       }, err => { 
         this.toastr.error(err.error.message, 'Error', {
           timeOut: 3000
         });
         this.loading = false;
         this.submitted = false;
      }); 
    } else {
      this.accountService.editSubject(this.key, data)
         .subscribe((result) => {
             if(result.status){
               this.toastr.success(result.msg, 'Success', {
                 timeOut: 3000
               });
               this.loading = false;
               this.submitted = false;
               this.navigateService.goToSubject();
             }else{
               this.toastr.error('Error occured please try again', 'Error', {
                 timeOut: 3000
               });
               this.loading = false;
               this.submitted = false;
             }
       }, err => { 
         this.toastr.error(err.error.message, 'Error', {
           timeOut: 3000
         });
         this.loading = false;
         this.submitted = false;
      }); 
    }
  }

}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}
