import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminUserComponent } from './admin-user.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: AdminUserComponent
  },
  {
    path: 'add-admin-user',
    loadChildren: './add-admin-user/add-admin-user.module#AddAdminUserModule',
    data: { breadcrumb: 'Add-Admin' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-admin-user/:id',
    loadChildren: './add-admin-user/add-admin-user.module#AddAdminUserModule',
    data: { breadcrumb: 'Edit-Admin' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminUserRoutingModule { }
