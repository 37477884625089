import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddAdminUserRoutingModule } from './add-admin-user-routing.module';
import { AddAdminUserComponent } from './add-admin-user.component';
import { CardModule } from 'src/app/theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddAdminUserComponent],
  imports: [
    CommonModule,
    AddAdminUserRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddAdminUserComponent]
})
export class AddAdminUserModule { }
