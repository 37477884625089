import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { JwtHelperService } from '@auth0/angular-jwt'
import { Subject, BehaviorSubject, Observable,ReplaySubject } from 'rxjs';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public emailData:String;
  private emailRecord: BehaviorSubject<String> = new BehaviorSubject<String>(this.emailData);


  public getEmailRecord(): Observable<String> {
    return this.emailRecord.asObservable();
  }

  public setEmailRecord(email: String): void {
    this.emailRecord.next(email);
  }

  setUserId(id) {
    if (id) {
      window.localStorage.setItem('KEY', id);
    }
  }

  setJwt(token) {
    if (token) {
      window.localStorage.setItem('TOKEN', token);
    }
  }

  setRJwt(token) {
    if (token) {
      window.localStorage.setItem('REFRESHTOKEN', token);
    }
  }

  setEmail(email) {
    if (email) {
      window.localStorage.setItem('EMAIL', email);
    }
  }

  setRole(userRole) {
    if (userRole) {
      window.localStorage.setItem('ROLE', userRole);
    }
  }

  setID(key) {
    if (key) {
      window.localStorage.setItem('KEY', key);
    }
  }

  public isOwner(key): boolean {
    const data = localStorage.getItem('KEY');
    return data == key? true : false;
  }

  getAuthToken(){
    const token = localStorage.getItem('TOKEN');
    return token;
  }

  getAuthRefreshToken(){
    const token = localStorage.getItem('REFRESHTOKEN');
    return token;
  }

  getEmail(){
    const email = localStorage.getItem('EMAIL');
    return email;
  }

  getRole(){
    const role = localStorage.getItem('ROLE');
    return role;
  }

  setCourse(course) {
    if (course) {
      window.localStorage.setItem('COURSE', course);
    }
  }

  getCourse(){
    const course = localStorage.getItem('COURSE');
    return course;
  }

  setUniverSity(university) {
    if (university) {
      window.localStorage.setItem('UNIVERSITY', university);
    }
  }

  getUniverSity(){
    const university = localStorage.getItem('UNIVERSITY');
    return university;
  }

  logout() {
    window.localStorage.clear();
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('TOKEN');
    // Check whether the token is expired and return
    // true or false
    return !jwtHelper.isTokenExpired(token);
  }

  localStorageClearByName(name) {
    localStorage.removeItem(name);
  }

  localStorageGetByName(name) {
    localStorage.getItem(name);
  }

  localStorageClearAll() {
    window.localStorage.clear();
  }
}
