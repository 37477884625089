import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUniversityComponent } from './add-university.component';


const routes: Routes = [
  {
    path: '',
    component: AddUniversityComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddUniversityRoutingModule { }
