<div class="row">
    <div class="col-xl-12">
      <app-card cardTitle="Assigned Faculties" [options]="false" blockClass="table-border-style">
        <!-- <div class="table-responsive">
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/course/add-course']">Add</button>
        </div> -->
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>#</th>              
              <th>Course Name</th>
              <th>Semester</th>
              <th>Subject Name</th>
              <th>Faculties Name</th>
              <!-- <th></th> -->
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of list;let i = index;" >
              <th scope="row">{{i+1}}</th>              
              <td>{{item.courseName}}</td>
              <td>{{item.semester}}</td>
              <td>{{item.subjectName}}</td>
              <td>{{item.professorName}}</td>
              <!-- <td><button class="btn btn-outline-primary shadow-2" (click)="onClickView(item.id)">Assign</button></td> -->
            </tr>
            </tbody>
          </table>
        </div>
      </app-card>      
    </div>
</div>

<!-- Add Modal -->
<div class="modal" id="myModal" [style.display]="ShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header" style="height: 50px;">
        <h4 class="modal-title">Assign Faculty</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="assignProfessorForm">
          <div class="form-group mb-3 w-75">
            <select class="form-control" (change)='onProfessorSelected($event)'
            id="professor" name="professor" formControlName="professor" 
            [ngClass]="{'is-invalid': !f.professor.valid && (f.professor.dirty || f.professor.touched || submitted)}">
              <option *ngFor="let item of professors" [value]="item.id" >
                {{item.batch}}
              </option>
            </select>
            <div *ngIf="!f.professor.valid && (f.professor.dirty || f.professor.touched || submitted)" class="invalid-feedback">
              <div *ngIf="f.professor.errors?.required">Faculty is required</div>
            </div>
          </div>
          <div class="form-group mb-3 w-75">
            <b>Email: {{email}}</b>
          </div>
          <div class="form-group mb-3 w-75">
          <b>Designation:</b> {{designation}}
          </div>
          <div class="form-group mb-3 w-75">
          <b>Education:</b> {{education}}
          </div>
          <div class="form-group mb-3 w-75">
          <b>Mobile Number: {{mobileNumber}}</b>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="height: 65px;">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Cancel</button>
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="onClickSave()">Save</button>
      </div>
    </div>
  </div>
</div>