import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent implements OnInit {

  key;
  contents
  params: Params;
  loading = false;

  isStudent = false;
  isTeacher = false;

  deleteShowModal = false;
  deleteID ;

  constructor(private route: ActivatedRoute, private accountService: AccountService, 
    private toastr: ToastrService, private router: Router, private authService: AuthService) {

  }

  ngOnInit(): void {
    if(this.authService.getRole() == 'student'){
      this.isStudent = true;
    } else if(this.authService.getRole() == 'professor'){
      this.isTeacher = true;
    }
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.key = id;
      });
    this.getContentsByUnitId();
  }

  getContentsByUnitId() {
    this.accountService.getContentsByUnitId(this.key)
      .subscribe((result: any) => {
        this.contents = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.contents.push(new Item(element._id, element.title, element.description, element.contentType, element.unitId, element.createdBy));
          });
        } else {
          this.contents = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickAdd(){

  }

  onClickEdit(id){
    this.router.navigate([`module/topic/${this.key}/editcontent/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteID = id;
  }

  goToContent(id){
    this.router.navigate([`module/topic/${this.key}/content/${id}`]);
  }

  goToAddContent(){
    this.router.navigate([`module/topic/${this.key}/addcontent`]);
  }

  isOwner(id){
    return this.authService.isOwner(id);
  }

  hide(){
    this.deleteShowModal = false;
  }

  onClickDeleteAction(){
    this.loading = true;
    this.accountService.deleteContent(this.deleteID)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.getContentsByUnitId();
      }else{
        this.toastr.error('Delete Content Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
      this.deleteShowModal = false;
    }, err => {
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      this.deleteShowModal = false;
    });
  }

}


export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, title:string, description:string, contentType:string, unitId:string, createdBy:string) {
        this.id = id;
        this.title = title;
        this.description = description
        this.contentType = contentType;
        this.unitId = unitId;
        this.createdBy = createdBy;
    }

    id:string;
    title:string;
    description:string;
    contentType:string;
    unitId:string;
    createdBy:string;
}
