import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {

  students: Array<Item>;
  loading = false;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getStudents();
  }

  getStudents() {
    this.accountService.getStudents()
      .subscribe((result: any) => {
        this.students = Array<Item>();
        if (result.status) {      
          console.log(result.data);    
          result.data.forEach((element, index) => {
            this.students.push(new Item(element._id, element.email, element.mobileNumber, element.isVerify, element.isApprove, element.course));
          });
        } else {
          this.students = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickVerify(item){
    if(item.isApprove){
      this.accountService.studentUnApprove(item.id)
      .subscribe((result: any) => {
        if (result.status) { 
          this.toastr.success(result.msg, 'Success', {
            timeOut: 3000
          });
          this.students.find(x=>x.id == item.id).isApprove = false;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
    }else{
      this.accountService.studentApprove(item.id)
      .subscribe((result: any) => {
        if (result.status) { 
          this.toastr.success(result.msg, 'Success', {
            timeOut: 3000
          });
          this.students.find(x=>x.id == item.id).isApprove = true;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
    }
  }

}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, email:string, phoneNumer:string, isVerify:boolean, isApprove:boolean, courseName:string) {
        this.id = id;
        this.email = email;
        this.phoneNumer = phoneNumer;
        this.courseName = courseName;
        this.isVerify = isVerify;
        this.isApprove = isApprove;
    }

    id:string;
    email:string;
    phoneNumer:string;
    courseName: string;
    isVerify: boolean;
    isApprove: boolean
}
