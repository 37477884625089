<ul class="navbar-nav">
  <li appToggleFullScreen><a href="javascript:" class="full-screen"><i class="feather icon-maximize"></i></a></li>
  <li class="nav-item dropdown" ngbDropdown placement="auto">
    <label *ngIf="isTeacher">{{universityName}}</label>
    <a *ngIf="isStudent" href="javascript:" data-toggle="dropdown" ngbDropdownToggle>{{coueseName}}</a>
    <ul *ngIf="isStudent" class="dropdown-menu" ngbDropdownMenu>
      <!-- <li><a class="dropdown-item" href="javascript:">BCom</a></li>
      <li><a class="dropdown-item" href="javascript:">BCom (CA)</a></li> -->
      <li><a class="dropdown-item" href="javascript:" *ngFor="let item of courses">{{item.name}}</a></li>
    </ul>
  </li>
  <!-- <li class="nav-item">
    <app-nav-search></app-nav-search>
  </li> -->
</ul>
