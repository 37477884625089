import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-professor',
  templateUrl: './professor.component.html',
  styleUrls: ['./professor.component.scss']
})
export class ProfessorComponent implements OnInit {

  professors: Array<Item>;
  loading = false;

  deleteShowModal: boolean;
  deleteId: string;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getProfessorUsers();
  }

  
  getProfessorUsers() {
    this.accountService.getProfessorUsers()
      .subscribe((result: any) => {
        this.professors = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.professors.push(new Item(element._id, element.email, element.mobileNumber, element.isSuspend, element.isActive, element.designation));
          });
        } else {
          this.professors = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  hide(){
    this.deleteShowModal = false;
  }

  professorDelete(){
    this.loading = true;
    this.accountService.deleteProfessorUser(this.deleteId)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.deleteShowModal = false;
        this.getProfessorUsers();
      }else{
        this.deleteShowModal = false;
        this.toastr.error('Delete Professor User Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
    }, err => {
      this.deleteShowModal = false;
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
    });
  }

  onClickEdit(id){
    this.router.navigate([`professor/add-professor/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteId = id;
  }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, email:string, phoneNumer:string, isSuspend:boolean, isActive:boolean, designation:string) {
        this.id = id;
        this.email = email;
        this.phoneNumer = phoneNumer;
        this.designation = designation;
        this.isSuspend = isSuspend;
        this.isActive = isActive;
    }

    id:string;
    email:string;
    phoneNumer:string;
    designation: string;
    isSuspend: boolean;
    isActive: boolean
}
