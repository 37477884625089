import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent implements OnInit {

  courses: Array<Item>;
  courseSelected;
  coueseName;
  universityName;

  isStudent = false;
  isTeacher = false;

  constructor(private accountService: AccountService, private toastr: ToastrService,
              private authService: AuthService) { }

  ngOnInit() {
    if(this.authService.getRole() == 'student'){
      this.isStudent = true;
      this.getCourses();
    }else if(this.authService.getRole() == 'professor'){
      this.isTeacher = true;
      this.getUniversities();
    }
  }

  onCourceSelected(event){
    const value = event.target.value;
    this.courseSelected = value;
    this.coueseName = this.courses.find(x=>x.id == this.courseSelected).name;
    this.authService.setCourse(this.courseSelected);
  }

  getUniversities() {
    this.accountService.getUniversitiesByUserId()
      .subscribe((result: any) => {
        if (result.status) {          
          result.data.forEach((element, index) => {  
              this.universityName = element.name;
              this.authService.setUniverSity(element._id);
          });
        } 
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getCourses() {
    this.accountService.getCoursesByUserId()
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {     
            if(index == 0){
              this.courseSelected = element._id;  
              this.coueseName = element.name;
              this.authService.setCourse(this.courseSelected);
            }   
            this.courses.push(new Item(element._id, element.name));
          });
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}
