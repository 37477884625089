import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {

  constructor(private router: Router) { }

  goToSignUp(){
    this.router.navigate(['/auth-signup']);
  }

  goToSignIn(){
    this.router.navigate(['/']);
  }

  goToDashboard(){
    this.router.navigate(['/dashboard/default']);
  }

  goToUniversity(){
    this.router.navigate(['/university']);
  }

  goToCourse(){
    this.router.navigate(['/course']);
  }

  goToBatch(){
    this.router.navigate(['/batch']);
  }

  goToSubject(){
    this.router.navigate(['/subject']);
  }

  goToModule(){
    this.router.navigate(['/module']);
  }
  
  goToTeacherModule(id){
    this.router.navigateByUrl(`/module/${id}`);
  }

  goToTopic(id){
    this.router.navigateByUrl(`/module/topic/${id}`);
  }
}
