<app-card cardTitle="Topics" [options]="false" blockClass="table-border-style">
<div class="row">
  <div class="col-md-12 col-xl-12">
    <div *ngIf="!isStudent" class="float-right mb-3 bg-secondary">
      <button class="btn btn-outline-primary" (click)="goToAddContent()">Add Content</button>
    </div>
  </div>
  <div class="col-md-12 col-xl-12"  *ngFor="let item of contents; let i = index;" >
    <app-card [hidHeader]="true">
      <h6 class="mb-4" style="color:#23b7e5">{{item.contentType | titlecase}} - {{item.title}}</h6>
      <div class="row d-flex align-items-center">
        <div class="col-9">
          <h4 class="f-w-300 d-flex align-items-center m-b-0">{{item.description}}</h4>
        </div>
      </div>
      <div class="float-right">
        <button class="btn btn-outline-primary" (click)="goToContent(item.id)">View</button>
        <button *ngIf="(!isStudent && !isTeacher && (item.contentType != 'pdf' && item.contentType != 'ppt')) || (!isStudent && (isTeacher && isOwner(item.createdBy)) && (item.contentType != 'pdf' && item.contentType != 'ppt'))" class="btn btn-outline-primary" (click)="onClickEdit(item.id)">Edit</button>
        <button *ngIf="(!isStudent && !isTeacher) || (!isStudent && (isTeacher && isOwner(item.createdBy)))" class="btn btn-outline-primary" [disabled]="loading" (click)="onClickDelete(item.id)">Delete</button>
      </div>
    </app-card>
  </div>
  <!-- <div class="col-md-12 col-xl-12">
    <app-card [hidHeader]="true">
      <h6 class="mb-4">Title</h6>
      <div class="row d-flex align-items-center">
        <div class="col-9">
          <h3 class="f-w-300 d-flex align-items-center m-b-0">Video Content</h3>
        </div>
      </div>
      <div class="float-right">
        <button class="btn btn-outline-primary" [routerLink]="['/module/topic/content']" [queryParams]="{key:'video'}">View</button>
        <button class="btn btn-outline-primary" (click)="onClickEdit()">Edit</button>
        <button class="btn btn-outline-primary" (click)="onClickDelete()">Delete</button>
      </div>
    </app-card>
  </div>
  <div class="col-md-12 col-xl-12">
    <app-card [hidHeader]="true">
      <h6 class="mb-4">Title</h6>
      <div class="row d-flex align-items-center">
        <div class="col-9">
          <h3 class="f-w-300 d-flex align-items-center m-b-0">PDF Content</h3>
        </div>
      </div>
      <div class="float-right">
        <button class="btn btn-outline-primary" [routerLink]="['/module/topic/content']" [queryParams]="{key:'pdf'}">View</button>
        <button class="btn btn-outline-primary" (click)="onClickEdit()">Edit</button>
        <button class="btn btn-outline-primary" (click)="onClickDelete()">Delete</button>
      </div>
    </app-card>
  </div> -->
</div>
</app-card>

<!-- Delete Modal -->
<div class="modal" id="myModal" [style.display]="deleteShowModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header" style="height: 50px;">
        <h4 class="modal-title">Delete Topic</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <label>Are you sure you want to delete this topic ?</label>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="height: 65px;">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="hide()">Cancel</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onClickDeleteAction()">Delete</button>
      </div>
    </div>
  </div>
</div>