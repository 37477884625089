import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleRoutingModule } from './module-routing.module';
import { ModuleComponent } from './module.component';
import { CardModule } from '../theme/shared/components';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [ModuleComponent],
  imports: [
    CommonModule,
    ModuleRoutingModule,
    CardModule,
    NgxPaginationModule
  ],
  exports: [ModuleComponent]
})
export class ModuleModule { }
