import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseComponent } from './course.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: CourseComponent
  },
  {
    path: 'add-course',
    loadChildren: './add-course/add-course.module#AddCourseModule',
    data: { breadcrumb: 'Add-Course' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-course/:id',
    loadChildren: './add-course/add-course.module#AddCourseModule',
    data: { breadcrumb: 'Edit-Course' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }
