

<app-card cardTitle="Add Batch" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
      <form [formGroup]="addBatchForm">
        <div class="card-body text-center">
            <div class="form-group mb-3 w-75">
              <label for="universityName" class="float-left">Institution Name</label>
                <select class="form-control" (change)='onUniversitySelected($event)' [attr.disabled]="'disabled'"
                id="university"name="university" formControlName="university" 
                [ngClass]="{'is-invalid': !f.university.valid && (f.university.dirty || f.university.touched || submitted)}">
                  <option *ngFor="let item of universities" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.university.valid && (f.university.dirty || f.university.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.university.errors?.required">Institution is required</div>
                </div>
              </div>               
              <div class="form-group text-left">
                <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="batch" [checked]="isAcademicYear" (change)="onBatchRadioChange('isAcademicYear')"> Academic Year
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="batch" [checked]="isCalendarYear" (change)="onBatchRadioChange('isCalendarYear')"> Calendar Year
                    </label>
                  </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="startYear" class="float-left">Start Year</label>
                <input type="month" class="form-control" placeholder="Date" id="startYear" name="startYear"
                formControlName="startYear" [ngClass]="{'is-invalid': f.startYear.invalid && (f.startYear.dirty || f.startYear.touched || submitted)}"/>
                <div *ngIf="!f.startYear.valid && (f.startYear.dirty || f.startYear.touched || f.startYear.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.startYear.errors?.required">Start Year is required</div>
                </div> 
              </div>
              <div class="form-group mb-3 w-75">
                <label for="endYear" class="float-left">End Year</label>
                <input type="month" class="form-control" placeholder="Date" id="endYear" name="endYear"
                formControlName="endYear" [ngClass]="{'is-invalid': f.endYear.invalid && (f.endYear.dirty || f.endYear.touched || submitted)}"/>
                <div *ngIf="!f.endYear.valid && (f.endYear.dirty || f.endYear.touched || f.endYear.errors || submitted)" class="invalid-feedback">
                  <div *ngIf="f.endYear.errors?.required">End Year is required</div>
                </div> 
              </div>
           <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/batch']">Cancel</button>
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddBatch()">{{buttonText}}</button>
        </div>
        </form>
    </div>       
  </app-card>