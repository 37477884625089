import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UniversityComponent } from './university.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: UniversityComponent
  },
  {
    path: 'add-university',
    loadChildren: './add-university/add-university.module#AddUniversityModule',
    data: { breadcrumb: 'Add-Institution' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-university/:id',
    loadChildren: './add-university/add-university.module#AddUniversityModule',
    data: { breadcrumb: 'Add-Institution' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UniversityRoutingModule { }
