import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})

export class AdminUserComponent implements OnInit {

  admins: Array<Item>;
  loading = false;

  deleteShowModal: boolean;
  deleteId: string;

  constructor(private accountService: AccountService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getAdminUsers();
  }

  getAdminUsers() {
    this.accountService.getAdminUsers()
      .subscribe((result: any) => {
        this.admins = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {
            this.admins.push(new Item(element._id, element.email, element.mobileNumber, element.isSuspend, element.isActive));
          });
        } else {
          this.admins = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onClickEdit(id){
    this.router.navigate([`admin-user/add-admin-user/${id}`]);
  }

  onClickDelete(id){
    this.deleteShowModal = true;
    this.deleteId = id;
  }

  hide(){
    this.deleteShowModal = false;
  }

  adminDelete(){
    this.loading = true;
    this.accountService.deleteAdminUser(this.deleteId)
    .subscribe((result: any) => {
      if(result.status){
        this.toastr.success(result.msg, 'Success', {
          timeOut: 3000
        });
        this.deleteShowModal = false;
        this.getAdminUsers();
      }else{
        this.deleteShowModal = false;
        this.toastr.error('Delete Admin User Failed', 'Error', {
          timeOut: 3000
        });
      }
      this.loading = false;
    }, err => {
      this.deleteShowModal = false;
      this.toastr.error(err.error.message, 'Error', {
        timeOut: 3000
      });
      this.loading = false;
    });
  }

}


export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, email:string, phoneNumer:string, isSuspend:boolean, isActive:boolean) {
        this.id = id;
        this.email = email;
        this.phoneNumer = phoneNumer;
        this.isSuspend = isSuspend;
        this.isActive = isActive;
    }

    id:string;
    email:string;
    phoneNumer:string;
    isSuspend: boolean;
    isActive: boolean
}
