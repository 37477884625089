<div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <div class="card-body text-center">
          <h3 class="mb-4">Reset Password</h3>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email">
          </div>
          <button class="btn btn-outline-primary mb-4" [routerLink]="['/']">Reset</button>
        </div>
      </div>
    </div>
  </div>
  