<app-card cardTitle="{{content.title}}" [options]="false" blockClass="table-border-style">
<div class="row p-10">
  <div *ngIf="isContent" class="col-md-12 col-xl-12 text-center">
    <app-card [hidHeader]="true">
      <h4 class="col-12 mb-4">{{content.description}}</h4>
      <div class="row d-flex align-items-center">
        <div class="col-12 text-center">     
          <p class="ck-content" style="text-align: justify;" [innerHtml]="content.data"></p>
        </div>
      </div>
      <!-- <div class="float-right m-2">
        <button class="btn btn-outline-primary" (click)="onClickEdit()">Edit</button>
        <button class="btn btn-outline-primary" (click)="onClickDelete()">Delete</button>
      </div> -->
    </app-card>
  </div>
  <div *ngIf="isVideo" class="col-md-12 col-xl-12 text-center">
    <app-card [hidHeader]="true">
      <h4 class="col-12 mb-4">{{content.description}}</h4>
      <div class="row d-flex align-items-center">
        <div class="col-12 text-center">
          <div class="text-center" [innerHtml]="iframe_html"></div>
        </div>
      </div>
      <!-- <div class="float-right mb-3">
        <button class="btn btn-outline-primary" (click)="onClickEdit()">Edit</button>
        <button class="btn btn-outline-primary" (click)="onClickDelete()">Delete</button>
      </div> -->
    </app-card>
  </div>
  <div *ngIf="isPdf" class="col-md-12 col-xl-12 text-center">
    <app-card [hidHeader]="true">
      <h4 class="col-12 mb-4">{{content.description}}</h4>
      <div class="row align-items-center">
        <div class="col-12 text-center">
          <div>
            <pdf-viewer [src]="pdfSrc" [fit-to-page]="true" [render-text]="true" style="display: block;border: 5px solid #4788cc;"></pdf-viewer>
          </div>
        </div>  
      </div>
      <!-- <div class="float-right mb-3">
        <button class="btn btn-outline-primary" (click)="onClickEdit()">Edit</button>
        <button class="btn btn-outline-primary" (click)="onClickDelete()">Delete</button>
      </div> -->
    </app-card>
  </div>
  <div *ngIf="isPPT" class="col-md-12 col-xl-12 text-center">
    <app-card [hidHeader]="true">
      <h4 class="col-12 mb-4">{{content.description}}</h4>
      <div class="row align-items-center">
        <div class="col-12 text-center">
          <div>
            <!-- <iframe src="{{pptUrl}}" style="width:600px; height:500px;" frameborder="0"></iframe> -->
            <iframe [attr.src]="pptUrl" style="width:600px; height:500px;" frameborder="0"></iframe>
          </div>
        </div>  
      </div>
      <!-- <div class="float-right mb-3">
        <button class="btn btn-outline-primary" (click)="onClickEdit()">Edit</button>
        <button class="btn btn-outline-primary" (click)="onClickDelete()">Delete</button>
      </div> -->
    </app-card>
  </div>
</div>
</app-card>