import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UniversityRoutingModule } from './university-routing.module';
import { UniversityComponent } from './university.component';
import { CardModule } from '../theme/shared/components';

@NgModule({
  declarations: [UniversityComponent],
  imports: [
    CommonModule,
    UniversityRoutingModule,
    CardModule
  ],
  exports: [UniversityComponent]
})
export class UniversityModule { }
