import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './guards/auth.guard'
import { PrivacyPolicyModule } from './demo/extra/privacy-policy/privacy-policy.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./demo/pages/authentication/auth-signin/auth-signin.module').then(m => m.AuthSigninModule)
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full',
        data: { breadcrumb: 'Dashboard' },
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: 'Dashboard' },
        canActivate: [AuthGuard]
      },
      {
        path: 'module',
        loadChildren: './module/module.module#ModuleModule',
        data: { breadcrumb: 'Modules' },
        canActivate: [AuthGuard]
      },
      {
        path: 'university',
        loadChildren: './university/university.module#UniversityModule',
        data: { breadcrumb: 'Institutions' },
        canActivate: [AuthGuard]
      },
      {
        path: 'course',
        loadChildren: './course/course.module#CourseModule',
        data: { breadcrumb: 'Courses' },
        canActivate: [AuthGuard]
      },
      {
        path: 'subject',
        loadChildren: './subject/subject.module#SubjectModule',
        data: { breadcrumb: 'Subjects' },
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-user',
        loadChildren: './admin-user/admin-user.module#AdminUserModule',
        data: { breadcrumb: 'Admins' },
        canActivate: [AuthGuard]
      },
      {
        path: 'professor',
        loadChildren: './professor/professor.module#ProfessorModule',
        data: { breadcrumb: 'Faculties' },
        canActivate: [AuthGuard]
      },
      {
        path: 'assign-professor',
        loadChildren: './assign-professor/assign-professor.module#AssignProfessorModule',
        data: { breadcrumb: 'Assigned-Faculties' },
        canActivate: [AuthGuard]
      },
      {
        path: 'batch',
        loadChildren: './batch/batch.module#BatchModule',
        data: { breadcrumb: 'Batch' },
        canActivate: [AuthGuard]
      },
      {
        path: 'student',
        loadChildren: './student/student.module#StudentModule',
        data: { breadcrumb: 'Studentes' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./demo/extra/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
