<app-card cardTitle="Add Unit / Module" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
      <form [formGroup]="addModuleForm">
        <div class="card-body text-center">
            <div class="form-group mb-3 w-75">
              <label for="universityName" class="float-left">Institution Name</label>
                <select class="form-control" (change)='onUniversitySelected($event)'
                id="university"name="university" formControlName="university" [attr.disabled]="(isTeacher === true || isAdmin === true) ? 'disabled' : null"
                [ngClass]="{'is-invalid': !f.university.valid && (f.university.dirty || f.university.touched || submitted)}">
                  <option *ngFor="let item of universities" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.university.valid && (f.university.dirty || f.university.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.university.errors?.required">Institution is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Department Name</label>
                <select class="form-control" (change)='onDepartmentSelected($event)'
                id="department"name="department" formControlName="department" [attr.disabled]="isTeacher === true ? 'disabled' : null"
                [ngClass]="{'is-invalid': !f.department.valid && (f.department.dirty || f.department.touched || submitted)}">
                  <option *ngFor="let item of departments" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.department.valid && (f.department.dirty || f.department.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.department.errors?.required">Department is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Course Name</label>
                <select class="form-control" (change)='onCourseSelected($event)'
                id="course" name="course" formControlName="course" [attr.disabled]="isTeacher === true ? 'disabled' : null"
                [ngClass]="{'is-invalid': !f.course.valid && (f.course.dirty || f.course.touched || submitted)}">
                  <option *ngFor="let item of courses" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.course.valid && (f.course.dirty || f.course.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.course.errors?.required">Course is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Subject Name</label>
                <select class="form-control" (change)='onSubjectSelected($event)'
                id="subject"name="subject" formControlName="subject" [attr.disabled]="isTeacher === true ? 'disabled' : null"
                [ngClass]="{'is-invalid': !f.subject.valid && (f.subject.dirty || f.subject.touched || submitted)}">
                  <option *ngFor="let item of subjects" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.subject.valid && (f.subject.dirty || f.subject.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.subject.errors?.required">Subject is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="batch" class="float-left">Batch</label>
                <select class="form-control" (change)='onBatchSelected($event)'
                id="batch"name="batch" formControlName="batch" 
                [ngClass]="{'is-invalid': !f.batch.valid && (f.batch.dirty || f.batch.touched || submitted)}">
                  <option *ngFor="let item of batches" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.batch.valid && (f.batch.dirty || f.batch.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.batch.errors?.required">Batch is required</div>
                </div>
              </div>
          <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Module / Unit Name</label>
            <input type="text" class="form-control" placeholder="Module / Unit Name" id="moduleName" name="moduleName"
            formControlName="moduleName" [ngClass]="{'is-invalid': f.moduleName.invalid && (f.moduleName.dirty || f.moduleName.touched || submitted)}"/>
            <div *ngIf="!f.moduleName.valid && (f.moduleName.dirty || f.moduleName.touched || f.moduleName.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.moduleName.errors?.required">Module is required</div>
            </div> 
           </div>  
           <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Description</label>
            <input type="text" class="form-control" placeholder="Description"id="description" name="description"
            formControlName="description" [ngClass]="{'is-invalid': f.description.invalid && (f.description.dirty || f.description.touched || submitted)}"/>
            <div *ngIf="!f.description.valid && (f.description.dirty || f.description.touched || f.description.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.description.errors?.required">Description is required</div>
            </div> 
           </div>  
           <button class="btn btn-outline-primary shadow-2 mb-4 float-right" (click)="onClickCancel()">Cancel</button>  
           <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddModule()">{{buttonText}}</button>
        </div>
        </form>
    </div>       
  </app-card>