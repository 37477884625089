

<app-card cardTitle="Add Course" [options]="false" blockClass="table-border-style">
    <div class="table-responsive">
      <form [formGroup]="addCourseForm">
        <div class="card-body text-center">
            <div class="form-group mb-3 w-75">
              <label for="universityName" class="float-left">Institution Name</label>
                <select class="form-control" (change)='onUniversitySelected($event)' [attr.disabled]="isAdmin === true ? 'disabled' : null"
                id="university"name="university" formControlName="university" 
                [ngClass]="{'is-invalid': !f.university.valid && (f.university.dirty || f.university.touched || submitted)}">
                  <option *ngFor="let item of universities" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.university.valid && (f.university.dirty || f.university.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.university.errors?.required">Institution is required</div>
                </div>
              </div>
              <div class="form-group mb-3 w-75">
                <label for="universityName" class="float-left">Department Name</label>
                <select class="form-control" (change)='onDepartmentSelected($event)'
                id="department"name="department" formControlName="department" 
                [ngClass]="{'is-invalid': !f.department.valid && (f.department.dirty || f.department.touched || submitted)}">
                  <option *ngFor="let item of departments" [value]="item.id" >
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="!f.department.valid && (f.department.dirty || f.department.touched || submitted)" class="invalid-feedback">
                  <div *ngIf="f.department.errors?.required">Department is required</div>
                </div>
              </div>
              
          <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Course Name</label>
            <input type="text" class="form-control" placeholder="Course Name"id="courseName" name="courseName"
            formControlName="courseName" [ngClass]="{'is-invalid': f.courseName.invalid && (f.courseName.dirty || f.courseName.touched || submitted)}"/>
            <div *ngIf="!f.courseName.valid && (f.courseName.dirty || f.courseName.touched || f.courseName.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.courseName.errors?.required">Course is required</div>
            </div> 
           </div>     
           <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Course Duration Type</label>
            <select class="form-control" (change)='onCourseDurationTypeSelected($event)'
            id="courseDurationType"name="courseDurationType" formControlName="courseDurationType" 
            [ngClass]="{'is-invalid': !f.courseDurationType.valid && (f.courseDurationType.dirty || f.courseDurationType.touched || submitted)}">
              <option *ngFor="let item of courseDurationTypes" [value]="item.id" >
                {{item.name}}
              </option>
            </select>
            <div *ngIf="!f.courseDurationType.valid && (f.courseDurationType.dirty || f.courseDurationType.touched || submitted)" class="invalid-feedback">
              <div *ngIf="f.courseDurationType.errors?.required">Course Duration Type is required</div>
            </div>
          </div>
           <div class="form-group mb-3 w-75">
            <label for="universityName" class="float-left">Course Duration</label>
            <input type="text" class="form-control" placeholder="Course Duration" id="courseTenure" name="courseTenure" (keypress)="numberOnly($event)"
            formControlName="courseTenure" [ngClass]="{'is-invalid': f.courseTenure.invalid && (f.courseTenure.dirty || f.courseTenure.touched || submitted)}"/>
            <div *ngIf="!f.courseTenure.valid && (f.courseTenure.dirty || f.courseTenure.touched || f.courseTenure.errors || submitted)" class="invalid-feedback">
              <div *ngIf="f.courseTenure.errors && !f.courseTenure.errors?.required">Course Duration maximum 6 years</div>
              <div *ngIf="f.courseTenure.errors?.required">Course Duration is required</div>
            </div>
          </div>     
           <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [routerLink]="['/course']">Cancel</button>
          <button class="btn btn-outline-primary shadow-2 mb-4 float-right" [disabled]="loading" (click)="onClickAddCourse()">{{buttonText}}</button>
        </div>
        </form>
    </div>       
  </app-card>