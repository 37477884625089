import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopicRoutingModule } from './topic-routing.module';
import { TopicComponent } from './topic.component';
import { CardModule } from 'src/app/theme/shared/components';


@NgModule({
  declarations: [TopicComponent],
  imports: [
    CommonModule,
    TopicRoutingModule,
    CardModule
  ],
  exports: [TopicComponent]
})
export class TopicModule { }
