import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.scss']
})
export class AddModuleComponent implements OnInit {

  universities: Array<Item>;
  universitySelected;

  courses: Array<Item>;
  courseSelected;

  subjects: Array<Item>;
  subjectSelected;

  addModuleForm: FormGroup;
  submitted = false;
  loading = false;
  key;

  subject;
  course;
  university;

  buttonText
  isTeacher = false;
  isAdmin = false;

  departments: Array<Item>;
  departmentSelected;
  department;

  subjectid;

  batches: Array<Item>;
  batchSelected;
  batch;

  dataChanged = false;
  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute, private authService: AuthService) {
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
  }

  ngOnInit(): void {
    if(this.authService.getRole() == 'professor'){
      this.isTeacher = true;
     } else if(this.authService.getRole() == 'admin'){
      this.isAdmin = true;
     }

    this.addModuleForm = this.formBuilder.group({
      university: ['', [Validators.required]],
      department: ['', [Validators.required]],
      course: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      moduleName: ['', [Validators.required]],
      batch: ['', [Validators.required]],
      description: ['']
    });
    
    if(this.isTeacher && this.key.includes('~s')){
      this.buttonText = 'Save';
      this.getTeacherDetails();
    }else{
      if(this.key){
        this.buttonText = 'Update';
        this.getModuleById(this.key);
      }else{
        this.buttonText = 'Save';
        if(this.isAdmin){        
          this.getAdminUniversities();
        }else{
          this.getUniversities();
        }    
      }
    }  
  }

   // convenience getter for easy access to form fields
   get f() { return this.addModuleForm.controls; }


   getUniversities() {
     this.accountService.getUniversities()
       .subscribe((result: any) => {
         this.universities = Array<Item>();
         if (result.status) {          
           result.data.forEach((element, index) => {     
             if(this.key){
              this.universitySelected = this.university._id;
              this.addModuleForm.controls['university'].setValue(this.university._id);    
             }else{
              if(this.isTeacher){
                this.universitySelected = this.authService.getUniverSity();
                this.addModuleForm.controls['university'].setValue(this.universitySelected); 
              } else if(index == 0){
                this.universitySelected = element._id;
                this.addModuleForm.controls['university'].setValue(element._id);    
              }  
             }
             this.universities.push(new Item(element._id, element.name));
           });
           if(this.universitySelected){
             if(this.isTeacher){
              this.getBatchesByTeacher(this.dataChanged);
             }else{
              this.getBatches(this.universitySelected, this.dataChanged);
             }              
            this.getDepartments(this.universitySelected, this.dataChanged);
           }
         } else {
           this.universities = null;
         }
       }, err => {
         this.toastr.error(err.error.message, 'Error', {
           timeOut: 3000
         });
       });
   }

   getAdminUniversities() {
    this.accountService.getAdminUniversities()
      .subscribe((result: any) => {
        this.universities = Array<Item>();
        if (result.status) {          
          result.data.forEach((element,index) => {     
            if(this.key){
              this.universitySelected = this.university._id;
              this.addModuleForm.controls['university'].setValue(this.university._id);                   
             }else{
              if(index == 0){
                this.universitySelected = element._id;
                this.addModuleForm.controls['university'].setValue(element._id); 
              }   
             }
            this.universities.push(new Item(element._id, element.name));
          });
          if(this.isTeacher){
            this.getBatchesByTeacher(this.dataChanged);
           }else{
            this.getBatches(this.universitySelected, this.dataChanged);
           } 
          this.getDepartments(this.universitySelected, this.dataChanged);
        } else {
          this.universities = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

   onUniversitySelected(event) {
     const value = event.target.value;
     this.universitySelected = value;
     this.addModuleForm.controls['university'].setValue(this.universitySelected);
     this.dataChanged = true;
     this.getDepartments(this.universitySelected, this.dataChanged);
     if(this.isTeacher){
      this.getBatchesByTeacher(this.dataChanged);
     }else{
      this.getBatches(this.universitySelected, this.dataChanged);
     } 
  }

  getDepartments(id, isChange) {
    this.accountService.getDepartmentsByUniversityId(id)
      .subscribe((result: any) => {
        this.departments = Array<Item>();
        if (result.status) {    
          result.data.department.forEach((element, index) => {
              if(this.key && !isChange){
                  this.departmentSelected = this.department.Id;
                  this.addModuleForm.controls['department'].setValue(this.departmentSelected);  
               }else{
                if(index == 0){
                  this.departmentSelected = element._id;
                  this.addModuleForm.controls['department'].setValue(element._id);  
                }  
            }             
            this.departments.push(new Item(element._id, element.departmentName));
          });          
          this.getCourses(this.departmentSelected, this.dataChanged);
        } else {
          this.departments = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onDepartmentSelected(event){
    const value = event.target.value;
    this.departmentSelected = value;
    this.addModuleForm.controls['department'].setValue(this.departmentSelected);
    this.dataChanged = true;
    this.getCourses(this.departmentSelected, true);
  }

  getCourses(id, isChange) {
    this.accountService.getCoursesByDepartmentId(id)
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {    
            if(this.key && !isChange){
                this.courseSelected = this.course._id;
                this.addModuleForm.controls['course'].setValue(this.course._id); 
             }else{
              if(index == 0){
                this.courseSelected = element._id;
                this.addModuleForm.controls['course'].setValue(element._id);    
              }         
            }
            this.courses.push(new Item(element._id, element.name));
          });
          if(this.courseSelected){
            // if(this.isTeacher){
            //   this.getSubjectsByTeacher(this.courseSelected);
            // }else{
              this.getSubjects(this.courseSelected, this.dataChanged);
            // }
          }
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getCourcesByTeacher(id, isChange) {
    this.accountService.getCourcesByTeacher(id)
      .subscribe((result: any) => {
        this.courses = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {    
            if(this.key && !isChange){
                this.courseSelected = this.course._id;
                this.addModuleForm.controls['course'].setValue(this.course._id); 
             }else{
              if(index == 0){
                this.courseSelected = element._id;
                this.addModuleForm.controls['course'].setValue(element._id);    
              }         
            }
            this.courses.push(new Item(element._id, element.name));
          });
          if(this.courseSelected){
            if(this.isTeacher){
              this.getSubjectsByTeacher(this.courseSelected, this.dataChanged);
            }else{
              this.getSubjects(this.courseSelected, this.dataChanged);
            }
          }
        } else {
          this.courses = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onCourseSelected(event){
    const value = event.target.value;
    this.courseSelected = value;
    this.addModuleForm.controls['course'].setValue(this.courseSelected);
    this.dataChanged = true;
    if(this.isTeacher){
      this.getSubjectsByTeacher(this.courseSelected, true);
    }else{
      this.getSubjects(this.courseSelected, true);
    }
  }


  getSubjects(id, isChange) {
    this.accountService.getSubjectsByCourseId(id)
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {    
            if(this.key && !isChange){
                this.subjectSelected = this.subject._id;
                this.addModuleForm.controls['subject'].setValue(this.subject._id);
             }else{
              if(index == 0){
                this.subjectSelected = element._id;
                this.addModuleForm.controls['subject'].setValue(element._id);    
              }      
            }   
            this.subjects.push(new Item(element._id, element.name));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }


  getSubjectsByTeacher(id, isChange) {
    this.accountService.getSubjectsByTeacher(id)
      .subscribe((result: any) => {
        this.subjects = Array<Item>();
        if (result.status) {          
          result.data.forEach((element, index) => {    
            if(this.key && !isChange){            
                this.subjectSelected = this.subject._id;
                this.addModuleForm.controls['subject'].setValue(this.subject._id); 
             }else{
              if(index == 0){
                this.subjectSelected = element._id;
                this.addModuleForm.controls['subject'].setValue(element._id);    
              }      
            }   
            this.subjects.push(new Item(element._id, element.name));
          });
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getBatches(id, isChange) {
    this.accountService.getBatchesByUniversityId(id)
      .subscribe((result: any) => {
        this.batches = Array<Item>();
        if (result.status) {  
          result.data.forEach((element, index) => {
              if(this.key && !isChange){
                if(this.batch){
                  this.batchSelected = this.batch.Id;
                  this.addModuleForm.controls['batch'].setValue(this.batchSelected);  
                }
               }else{
                if(index == 0){
                  this.batchSelected = element._id;
                  this.addModuleForm.controls['batch'].setValue(element._id);  
                }  
            }             
            this.batches.push(new Item(element._id,`${element.name} (${element.startYear}-${element.endYear})`));
          });          
        } else {
          this.batches = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }


  getBatchesByTeacher(isChange) {
    this.accountService.getBatchesByTeacherId()
      .subscribe((result: any) => {
        this.batches = Array<Item>();
        if (result.status) {  
          result.data.forEach((element, index) => {
              if(this.key && !isChange){
                if(this.batch){
                  this.batchSelected = this.batch.Id;
                  this.addModuleForm.controls['batch'].setValue(this.batchSelected);  
                }
               }else{
                if(index == 0){
                  this.batchSelected = element._id;
                  this.addModuleForm.controls['batch'].setValue(element._id);  
                }  
            }             
            this.batches.push(new Item(element._id,`${element.name} (${element.startYear}-${element.endYear})`));
          });          
        } else {
          this.batches = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onBatchSelected(event){
    const value = event.target.value;
    this.batchSelected = value;
    this.addModuleForm.controls['batch'].setValue(this.batchSelected);
  }

  getModuleById(id) {
    this.accountService.getModuleById(id)
      .subscribe((result: any) => {
        if (result.status) {    
          this.subject = result.data.subjectId;
          this.course = result.data.subjectId.courseId;
          this.university = result.data.subjectId.courseId.universityId;
          this.department = result.data.subjectId.courseId.department;
          this.batch = result.data.subjectId.batchId;
          this.addModuleForm.controls['university'].setValue(result.data.subjectId);
          this.addModuleForm.controls['subject'].setValue(result.data.subjectId);
          this.addModuleForm.controls['moduleName'].setValue(result.data.name);  
          this.addModuleForm.controls['description'].setValue(result.data.description);  
          this.addModuleForm.controls['batch'].setValue(result.data.batchId);
          if(this.isAdmin){        
            this.getAdminUniversities();
          }else{
            this.getUniversities();
          }  
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  getTeacherDetails() {
    let subjectid = this.key.replace('~s','');
    this.accountService.getSubjectByTeacherId(subjectid)
      .subscribe((result: any) => {
        if (result.status) {  
          this.subject = { _id: result.data._id, name: result.data.name };
          this.course =  { _id: result.data.courseId._id, name: result.data.courseId.name };
          this.university = { _id: result.data.courseId.universityId._id, name: result.data.courseId.universityId.name };
          this.department = result.data.courseId.department;
          this.getUniversities();
        } else {
          this.subjects = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onSubjectSelected(event){
    const value = event.target.value;
    this.subjectSelected = value;
    this.addModuleForm.controls['subject'].setValue(this.subjectSelected);
  }

  onClickAddModule(){

    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.addModuleForm.invalid) {
      this.loading = false;
        return;
    }

    if(this.universities.length == 0){
      this.toastr.error('Please Select University', 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      return;
    }

    if(this.courses.length == 0){
      this.toastr.error('Please Select Course', 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      return;
    }

    if(this.subjects.length == 0){
      this.toastr.error('Please Select Subject', 'Error', {
        timeOut: 3000
      });
      this.loading = false;
      return;
    }

    const  data = {
      "subjectId": this.addModuleForm.controls['subject'].value,
      "batchId": this.addModuleForm.controls['batch'].value,
      "name": this.addModuleForm.controls['moduleName'].value,
      "description": this.addModuleForm.controls['description'].value
    }

    if(this.key){
      if(this.key.includes('~s')){
        this.accountService.addModule(data)
        .subscribe((result) => {
            if(result.status){
              this.toastr.success(result.msg, 'Success', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
              if(this.isTeacher && this.key.includes('~s')){
                let subjectid = this.key.replace('~s','');
                this.navigateService.goToTeacherModule(subjectid);
              }else{
                this.navigateService.goToModule();
              }
            }else{
              this.toastr.error('Error occured please try again', 'Error', {
                timeOut: 3000
              });
              this.loading = false;
              this.submitted = false;
            }
          }, err => { 
            this.toastr.error(err.error.message, 'Error', {
              timeOut: 3000
            });
            this.loading = false;
            this.submitted = false;
        }); 
      }else{
            this.accountService.editModule(this.key, data)
            .subscribe((result) => {
                if(result.status){
                  this.toastr.success(result.msg, 'Success', {
                    timeOut: 3000
                  });
                  this.loading = false;
                  this.submitted = false;
                  if(this.isTeacher && this.key.includes('~s')){
                    let subjectid = this.key.replace('~s','');
                    this.navigateService.goToTeacherModule(subjectid);
                  }else{
                    this.navigateService.goToModule();
                  }
                }else{
                  this.toastr.error('Error occured please try again', 'Error', {
                    timeOut: 3000
                  });
                  this.loading = false;
                  this.submitted = false;
                }
          }, err => { 
            this.toastr.error(err.error.message, 'Error', {
              timeOut: 3000
            });
            this.loading = false;
            this.submitted = false;
        });
      } 
    } else {
          this.accountService.addModule(data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                if(this.isTeacher && this.key.includes('~s')){
                  let subjectid = this.key.replace('~s','');
                  this.navigateService.goToTeacherModule(subjectid);
                }else{
                  this.navigateService.goToModule();
                }
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
      }); 
    }
   }

   onClickCancel(){
    if(this.isTeacher && this.key.includes('~s')){
      let subjectid = this.key.replace('~s','');
      this.navigateService.goToTeacherModule(subjectid);
    }else{
      this.navigateService.goToModule();
    }
   }

}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}
