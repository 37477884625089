import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddcontentRoutingModule } from './addcontent-routing.module';
import { AddcontentComponent } from './addcontent.component';
import { CardModule } from 'src/app/theme/shared/components';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddcontentComponent],
  imports: [
    CommonModule,
    AddcontentRoutingModule,
    CardModule,
    CKEditorModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddcontentComponent]
})
export class AddcontentModule { }
