import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigateService } from 'src/app/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.scss']
})
export class AddCourseComponent implements OnInit {

  universities: Array<Item>;
  universitySelected;

  addCourseForm: FormGroup;
  submitted = false;
  loading = false;

  key;
  buttonText
  university;
  
  departments: Array<Item>;
  departmentSelected;

  department;

  courseDurationTypes: Array<Item>;
  courseDurationTypeSelected;

  isAdmin = false;

  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private navigateService: NavigateService,
    private route: ActivatedRoute,
    private authService: AuthService) { 
      this.route.params.subscribe(params => {
        let id = params['id'];
        this.key = id;
      });
    }

  ngOnInit(): void {
    this.addCourseForm = this.formBuilder.group({
      university: ['', [Validators.required]],
      department: ['', [Validators.required]],
      courseName: ['', [Validators.required]],
      courseDurationType: ['', [Validators.required]],
      courseTenure: ['', [Validators.required, Validators.max(6)]],
    });
    if(this.authService.getRole() == 'admin'){
      this.isAdmin = true;
    }
    if(this.key){
      this.buttonText = 'Update';
      this.getCourseById(this.key);
    }else{
      this.buttonText = 'Save';
      if(this.isAdmin){        
        this.getAdminUniversities();
      }else{
        this.getUniversities();
      }      
    }
    this.getCourseDurationType();
  }

    // convenience getter for easy access to form fields
    get f() { return this.addCourseForm.controls; }


    getUniversities() {
      this.accountService.getUniversities()
        .subscribe((result: any) => {
          this.universities = Array<Item>();
          if (result.status) {          
            result.data.forEach((element,index) => {     
              if(this.key){
                this.universitySelected = this.university._id;
                this.addCourseForm.controls['university'].setValue(this.university._id);                   
               }else{
                if(index == 0){
                  this.universitySelected = element._id;
                  this.addCourseForm.controls['university'].setValue(element._id); 
                }   
               }
              this.universities.push(new Item(element._id, element.name));
            });
            this.getDepartments(this.universitySelected);
          } else {
            this.universities = null;
          }
        }, err => {
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
        });
    }


    getAdminUniversities() {
      this.accountService.getAdminUniversities()
        .subscribe((result: any) => {
          this.universities = Array<Item>();
          if (result.status) {          
            result.data.forEach((element,index) => {     
              if(this.key){
                this.universitySelected = this.university._id;
                this.addCourseForm.controls['university'].setValue(this.university._id);                   
               }else{
                if(index == 0){
                  this.universitySelected = element._id;
                  this.addCourseForm.controls['university'].setValue(element._id); 
                }   
               }
              this.universities.push(new Item(element._id, element.name));
            });
            this.getDepartments(this.universitySelected);
          } else {
            this.universities = null;
          }
        }, err => {
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
        });
    }

    onUniversitySelected(event) {
      const value = event.target.value;
      this.universitySelected = value;
      this.addCourseForm.controls['university'].setValue(this.universitySelected);
      this.getDepartments(this.universitySelected);
   }

   getDepartments(id) {
    this.accountService.getDepartmentsByUniversityId(id)
      .subscribe((result: any) => {
        this.departments = Array<Item>();
        if (result.status) {    
          result.data.department.forEach((element, index) => {
              if(this.key){
                this.departmentSelected = this.department.Id;
                this.addCourseForm.controls['department'].setValue(this.departmentSelected);  
               }else{
                if(index == 0){
                  this.departmentSelected = element._id;
                  this.addCourseForm.controls['department'].setValue(element._id);  
                }  
            }             
            this.departments.push(new Item(element._id, element.departmentName));
          });
        } else {
          this.departments = null;
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }

  onDepartmentSelected(event){
    const value = event.target.value;
    this.departmentSelected = value;
    this.addCourseForm.controls['department'].setValue(this.departmentSelected);
  }

   getCourseById(id) {
    this.accountService.getCourseById(id)
      .subscribe((result: any) => {
        if (result.status) {
         this.university = result.data.universityId; 
         this.department = result.data.department; 
         this.addCourseForm.controls['courseName'].setValue(result.data.name);
         this.addCourseForm.controls['courseDurationType'].setValue(result.data.courseDurationType);
         this.addCourseForm.controls['courseTenure'].setValue(result.data.courseTenure);
         if(this.isAdmin){
          this.getAdminUniversities();
          }else{
            this.getUniversities();
          }    
        } else {
          
        }
      }, err => {
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 3000
        });
      });
  }


  getCourseDurationType(){
    this.courseDurationTypes = Array<Item>();
    this.courseDurationTypes.push(new Item('Hours', 'Hours'));
    this.courseDurationTypes.push(new Item('Weeks', 'Weeks'));
    this.courseDurationTypes.push(new Item('Monthly', 'Monthly'));
    this.courseDurationTypes.push(new Item('Yearly', 'Yearly'));
   }

  onCourseDurationTypeSelected(event) {
    const value = event.target.value;
    this.courseDurationTypeSelected = value;
    this.addCourseForm.controls['courseDurationType'].setValue(this.courseDurationTypeSelected);
 }

   onClickAddCourse(){

      this.submitted = true;
      this.loading = true;
  
      // stop here if form is invalid
      if (this.addCourseForm.invalid) {
        this.loading = false;
          return;
      }

      if(this.universities.length == 0){
        this.toastr.error('Please Select University', 'Error', {
          timeOut: 3000
        });
        this.loading = false;
        return;
      }
  
      const  data = {
        "universityId": this.addCourseForm.controls['university'].value,
        "department": { Id: this.addCourseForm.controls['department'].value, 
                        departmentName: this.departments.find(x=>x.id == this.departmentSelected).name 
                      },
        "name": this.addCourseForm.controls['courseName'].value,
        "courseDurationType": this.addCourseForm.controls['courseDurationType'].value,
        "courseTenure": this.addCourseForm.controls['courseTenure'].value
      }
  
      if(!this.key){
      this.accountService.addCourse(data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.navigateService.goToCourse();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      } else {
        this.accountService.editCourse(this.key, data)
          .subscribe((result) => {
              if(result.status){
                this.toastr.success(result.msg, 'Success', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
                this.navigateService.goToCourse();
              }else{
                this.toastr.error('Error occured please try again', 'Error', {
                  timeOut: 3000
                });
                this.loading = false;
                this.submitted = false;
              }
        }, err => { 
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 3000
          });
          this.loading = false;
          this.submitted = false;
       }); 
      }
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
}

export class list  
{
  item: Item;
}

export class Item
{
    constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
    }

    id:string;
    name:string;
}
