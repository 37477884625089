import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddSubjectRoutingModule } from './add-subject-routing.module';
import { AddSubjectComponent } from './add-subject.component';
import { CardModule } from 'src/app/theme/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AddSubjectComponent],
  imports: [
    CommonModule,
    AddSubjectRoutingModule,
    CardModule,
    FormsModule,    //added here too
    ReactiveFormsModule //added here too
  ],
  exports: [AddSubjectComponent]
})
export class AddSubjectModule { }
